export const GET_HOME_CONTENT = "GET_HOME_CONTENT";
export const GET_HOME_CONTENT_BEGIN = "GET_HOME_CONTENT_BEGIN";
export const GET_HOME_CONTENT_SUCCESS = "GET_HOME_CONTENT_SUCCESS";
export const GET_HOME_CONTENT_FAIL = "GET_HOME_CONTENT_FAIL ";

export const ADD_HOME_CONTENT = "ADD_HOME_CONTENT";
export const ADD_HOME_CONTENT_BEGIN = "ADD_HOME_CONTENT_BEGIN";
export const ADD_HOME_CONTENT_SUCCESS = "ADD_HOME_CONTENT_SUCCESS";
export const ADD_HOME_CONTENT_FAIL = "ADD_HOME_CONTENT_FAIL";

