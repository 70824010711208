import { call, put, takeEvery } from "redux-saga/effects";
import {
  getHeaderContentBegin,
  getHeaderContentFail,
  getHeaderContentSuccess,
} from "./action";
import { get } from "../../helpers/api_helpers";
import { GET_HEADER_CONTENT } from "./actionTypes";

function* getHeaderContent({ data }) {
  try {
    yield put(getHeaderContentBegin());
    const response = yield call(get, `/header_footer_content`, data);
    if (response) {
      yield put(getHeaderContentSuccess(response.result));
    }
  } catch (error) {
    yield put(getHeaderContentFail(error));
  }
}

function* HeaderContentSaga() {
  yield takeEvery(GET_HEADER_CONTENT, getHeaderContent);
}

export default HeaderContentSaga;
