import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getNewsCategory, getNewsList } from "../../store/News/action";

const NewsCategory = ({ setRequest, request, t, selected, HeaderContent }) => {
  const dispatch = useDispatch();
  const newsData = useSelector((state) => state?.News);
  const [category, setCategory] = useState("");

  useEffect(() => {
    dispatch(getNewsCategory({ id: selected }));
  }, [selected]);

  return (
    <>
      <ul className="category-responsive">
        {!newsData?.loading && newsData?.categories?.news_category?.length ? (
          <li style={{ listStyle: "none" }}>
            <a
              href={`/${selected}/news`}
              onClick={(e) => {
                e.preventDefault();
                setRequest({
                  ...request,
                  category: "",
                  cat_name: "",
                  keyword: "",
                  page: 1,
                });
                window.scrollTo(0, 0);
                setCategory("");
              }}
              className={category === "" ? "cat-link" : ""}
            >
              {HeaderContent?.header?.all}
              <span>({newsData?.categories?.total_count})</span>
            </a>
          </li>
        ) : null}
        {newsData?.categories?.news_category?.length && !newsData?.loading
          ? newsData?.categories?.news_category?.map((cat) => (
              <li style={{ listStyle: "none" }} key={cat?.category_title}>
                <a
                  href={`/${selected}/news`}
                  onClick={(e) => {
                    e.preventDefault();
                    setRequest({
                      ...request,
                      category: cat?.cat_id,
                      cat_name: cat?.category_title,
                      page: 1,
                      keyword: "",
                    });
                    window.scrollTo(0, 0);
                    setCategory(cat.category_title);
                  }}
                  className={category === cat?.category_title ? "cat-link" : ""}
                >
                  {cat?.category_title} <span>({cat?.news_count})</span>
                </a>
              </li>
            ))
          : null}
      </ul>
    </>
  );
};

export default NewsCategory;
