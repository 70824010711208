import {
  ADD_NEWS_BEGIN,
  ADD_NEWS_FAIL,
  ADD_NEWS_SUCCESS,
  GET_NEWS_LIST_BEGIN,
  GET_NEWS_LIST_FAIL,
  GET_NEWS_LIST_SUCCESS,
  GET_NEWS_BEGIN,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  GET_LATEST_NEWS_BEGIN,
  GET_LATEST_NEWS_SUCCESS,
  GET_LATEST_NEWS_FAIL,
  GET_NEWS_CATEGORY_BEGIN,
  GET_NEWS_CATEGORY_SUCCESS,
  GET_NEWS_CATEGORY_FAIL,
} from "./actionTypes";

export const initial_state = {
  newsList: {},
  loading: false,
  latestNewsList: {},
  newsDetailsError: "",
  error: "",
  news: {},
  total: 0,
  categories: [],
};

const News = (state = initial_state, action) => {
  switch (action.type) {
    case GET_NEWS_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_NEWS_LIST_SUCCESS:
      return {
        ...state,
        newsList: action.payload.news,
        total: action?.payload?.total,
        loading: false,
        error: "",
      };

    case GET_NEWS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_LATEST_NEWS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_LATEST_NEWS_SUCCESS:
      return {
        ...state,
        latestNewsList: action.payload.news,
        loading: false,
        error: "",
      };

    case GET_LATEST_NEWS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_NEWS_BEGIN:
      return {
        ...state,
        news: "",
        loading: true,
        newsDetailsError: "",
      };

    case GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
        loading: false,
        error: "",
        newsDetailsError: "",
      };

    case GET_NEWS_FAIL:
      return {
        ...state,
        loading: false,
        newsDetailsError: action.payload,
      };

    case GET_NEWS_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
        categories: "",
      };

    case GET_NEWS_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: false,
        error: "",
      };

    case GET_NEWS_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_NEWS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ADD_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        newsList: action.payload,
      };

    case ADD_NEWS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export default News;
