import {
  GET_LANGUAGES,
  GET_LANGUAGES_BEGIN,
  GET_LANGUAGES_FAIL,
  GET_LANGUAGES_SUCCESS,
} from "./actionTypes";

export const getLanguages = () => ({
  type: GET_LANGUAGES,
});

export const getLanguagesBegin = () => ({
  type: GET_LANGUAGES_BEGIN,
});

export const getLanguagesSuccess = (language) => ({
  type: GET_LANGUAGES_SUCCESS,
  payload: language,
});

export const getLanguagesFail = (error) => ({
  type: GET_LANGUAGES_FAIL,
  payload: error,
});
