import axios from "axios";

// const API_URL = "https://backendapi-standby.flashat.com";
const API_URL = "https://backendapi.flashat.com";
// const API_URL = "http://192.168.1.113:5000/";
// const API_URL = "http://localhost:5000/";
const axiosApi = axios.create({
  baseURL: API_URL,
});

const policiesApi = axios.create({
  baseURL: "http://user-staging.messej.com",
});

export async function get(url, data, config = {}) {
  return await axiosApi
    .get(url, { params: { ...data } }, { ...config })
    .then((response) => response.data);
}

export async function add(url, data, config = {}) {
  return await axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}

export async function update(url, data, config = {}) {
  return await axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export async function getPolicy(url, data, config = {}) {
  return await policiesApi
    .get(url, { params: { ...data } }, { ...config })
    .then((response) => response.data);
}

export function base_URL() {
  return API_URL;
}
