import {
  CONTACT_US,
  CONTACT_US_BEGIN,
  CONTACT_US_FAIL,
  CONTACT_US_SUCCESS,
  GET_CONTACT_US_CATEGORY,
  GET_CONTACT_US_CATEGORY_BEGIN,
  GET_CONTACT_US_CATEGORY_FAIL,
  GET_CONTACT_US_CATEGORY_SUCCESS,
} from "./actionTypes";

export const contactUs = ({ message, toggle }) => {
  return {
    type: CONTACT_US,
    message: message,
    toggle: toggle,
  };
};

export const contactUsBegin = () => {
  return {
    type: CONTACT_US_BEGIN,
  };
};

export const contactUsSuccess = (message) => {
  return {
    type: CONTACT_US_SUCCESS,
    payload: message,
  };
};

export const contactUsFail = (error) => {
  return {
    type: CONTACT_US_FAIL,
    payload: error,
  };
};

export const contactUsCategory = ({ id }) => {
  return {
    type: GET_CONTACT_US_CATEGORY,
    id: id,
  };
};

export const contactUsCategoryBegin = () => {
  return {
    type: GET_CONTACT_US_CATEGORY_BEGIN,
  };
};

export const contactUsCategorySuccess = (category) => {
  return {
    type: GET_CONTACT_US_CATEGORY_SUCCESS,
    payload: category,
  };
};

export const contactUsCategoryFail = (error) => {
  return {
    type: GET_CONTACT_US_CATEGORY_FAIL,
    payload: error,
  };
};
