import { call, put, takeEvery } from "@redux-saga/core/effects";
import { get } from "../../helpers/api_helpers";
import {
  getLanguagesBegin,
  getLanguagesFail,
  getLanguagesSuccess,
} from "./action";
import { GET_LANGUAGES } from "./actionTypes";

function* getLanguages({ data }) {
  try {
    yield put(getLanguagesBegin());
    const response = yield call(get, `/languages`, data);
    if (response) {
      yield put(getLanguagesSuccess(response.result));
    }
  } catch (error) {
    yield put(getLanguagesFail(error));
  }
}

function* LanguagesSaga() {
  yield takeEvery(GET_LANGUAGES, getLanguages);
}

export default LanguagesSaga;
