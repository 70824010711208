import {
  GET_ACCOUNT_DELETE,
  GET_ACCOUNT_DELETE_BEGIN,
  GET_ACCOUNT_DELETE_FAIL,
  GET_ACCOUNT_DELETE_SUCCESS,
} from "./actionTypes";

export const getAccountDelete = ({ data }) => ({
  type: GET_ACCOUNT_DELETE,
  data: data,
});

export const getAccountDeleteBegin = () => ({
  type: GET_ACCOUNT_DELETE_BEGIN,
});

export const getAccountDeleteSuccess = (language) => ({
  type: GET_ACCOUNT_DELETE_SUCCESS,
  payload: language,
});

export const getAccountDeleteFail = (error) => ({
  type: GET_ACCOUNT_DELETE_FAIL,
  payload: error,
});
