import { call, put, takeEvery } from "redux-saga/effects";
import {
  countFaqHelpfulResponseBegin,
  countFaqHelpfulResponseFail,
  countFaqHelpfulResponseSuccess,
  countFaqNotHelpfulResponseBegin,
  countFaqNotHelpfulResponseFail,
  countFaqNotHelpfulResponseSuccess,
  getFAQBegin,
  getFAQFail,
  getFAQSuccess,
} from "./action";
import {
  COUNT_FAQ_HELPFUL_RESPONSE,
  COUNT_FAQ_NOT_HELPFUL_RESPONSE,
  GETFAQ,
} from "./actionType";
import { get } from "../../helpers/api_helpers";

function* getFAQ({ request }) {
  try {
    yield put(getFAQBegin());
    const response = yield call(get, "/list_faq", request);
    if (response) {
      yield put(getFAQSuccess(response.result.faqs));
    }
  } catch (error) {
    yield put(getFAQFail(error));
  }
}

function* countFaqHelpfulResponse({ id, callback }) {
  try {
    yield put(countFaqHelpfulResponseBegin());
    const response = yield call(get, `/faq_helpful/${id}`);
    if (response) {
      yield put(countFaqHelpfulResponseSuccess());
      callback && callback();
    }
  } catch (error) {
    yield put(countFaqHelpfulResponseFail(error));
  }
}

function* countFaqNotHelpfulResponse({ id, callback }) {
  try {
    yield put(countFaqNotHelpfulResponseBegin());
    const response = yield call(get, `/faq_nothelpful/${id}`);
    if (response) {
      yield put(countFaqNotHelpfulResponseSuccess());
      callback && callback();
    }
  } catch (error) {
    yield put(countFaqNotHelpfulResponseFail(error));
  }
}

function* faqSaga() {
  yield takeEvery(GETFAQ, getFAQ);
  yield takeEvery(COUNT_FAQ_HELPFUL_RESPONSE, countFaqHelpfulResponse);
  yield takeEvery(COUNT_FAQ_NOT_HELPFUL_RESPONSE, countFaqNotHelpfulResponse);
}

export default faqSaga;
