import { Link } from "react-router-dom";
import NewsPagination from "../../components/Pagination/index";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import i18n from "i18next";

const HighlightNews = ({
  newsDetails,
  request,
  setRequest,
  title,
  t,
  selected,
  total,
  HeaderContent,
}) => {
  const [hovered, setHovered] = useState(false);
  const [id, setId] = useState(null);

  const onMouseEnter = (id) => {
    setHovered(true);
    setId(id);
  };

  const onMouseLeave = (id) => {
    setHovered(false);
    setId(null);
  };

  useEffect(() => {
    const scroll = document?.querySelector(".scroll");

    window?.addEventListener("scroll", () => {
      scroll?.classList?.toggle("active", window.scrollY > 200);
    });

    function scrollToTop() {
      window?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    scroll?.addEventListener("click", () => {
      scrollToTop();
    });
  }, []);

  const location = useLocation();

  // useEffect(() => {
  //   i18n.changeLanguage(location.pathname.slice(1, 6));
  // }, [location.pathname]);

  const cat_title = title?.slice(0, 1) + title?.slice(1)?.toLowerCase();

  return (
    <div className="col-lg-9 d-flex flex-column">
      <h1 className="font-weight-bold mb-5 me-blog-title">
        {!newsDetails?.loading && title
          ? cat_title
          : newsDetails?.loading
          ? ""
          : selected == "ar-AE"
          ? HeaderContent?.header?.news
          : HeaderContent?.header?.news &&
            HeaderContent?.header?.news?.substr(0, 1) +
              HeaderContent?.header?.news?.substr(1).toLowerCase()}
      </h1>
      <div className="scroll">
        <i className="fa fa-chevron-right top"></i>
      </div>
      <div className="row ">
        {!newsDetails?.loading && newsDetails?.newsList?.length ? (
          newsDetails?.newsList?.map((news) => (
            <div
              className="col-md-6 mb-5 highlight-news-responsive"
              key={news.id}
            >
              <a href={`/${selected}/news-list/${news.news_id}`}>
                <div
                  onMouseEnter={() => onMouseEnter(news.id)}
                  onMouseLeave={() => onMouseLeave(news.id)}
                >
                  {news?.image_path && (
                    <img
                      src={news?.image_path}
                      alt="news"
                      className={`img-responsive mb-1 ${
                        selected === "ar-AE" ? "ml-5" : ""
                      }`}
                      // height="299px"
                      // width="398px"
                    />
                  )}
                  <div>
                    <h6
                      className="mt-1 news-content-date"
                      style={{ color: "#918e8e", fontSize: "0.9rem" }}
                    >
                      {/* {news.published_date} */}
                      {new Date(`${news.published_date} UTC`)
                        .toString()
                        .slice(3, 21)}
                    </h6>
                    <div>
                      <h5
                        className="me-blog-title news-content font-weight-bold "
                        style={
                          hovered && id === news.id ? { color: "#b227c1" } : {}
                        }
                      >
                        {`${
                          news.title.length > 50
                            ? `${news.title.slice(0, 50)}...`
                            : news.title
                        }`}
                      </h5>
                      <h6 className="short-description">
                        {news?.content
                          ?.replace(/<\/?[^>]+(>|$)/g, "")
                          .slice(0, 130)}
                        ...
                      </h6>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))
        ) : newsDetails?.loading ? null : (
          <p className={selected == "ar-AE" ? "mr-3" : "ml-3"}>
            {HeaderContent?.header?.noData}
          </p>
        )}
      </div>

      {!newsDetails?.loading && newsDetails?.newsList?.length ? (
        <NewsPagination
          totalRecords={total}
          loading={newsDetails?.loading}
          setRequest={setRequest}
          request={request}
        />
      ) : null}
    </div>
  );
};

export default HighlightNews;
