import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row } from "reactstrap";
import Logo from "../../assets/images/flashat-logo.svg";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLegalDocumentType } from "../../store/Policies/action";

const Footer = () => {
  const [aStateVariable, setAStateVariable] = useState(false);
  const HeaderContent = useSelector((state) => state.HeaderContent);
  const Policies = useSelector((state) => state.Policies);

  console.log(Policies);

  const selected = localStorage?.getItem("i18nextLng");

  console.log(selected);

  useEffect(() => {
    if (aStateVariable === true) {
      window.scrollTo(0, 0);
      setAStateVariable(false);
    }
  }, [aStateVariable]);

  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLegalDocumentType({ lang: selected }));
  }, [selected]);

  return (
    <>
      {location.pathname ==
      "/unsubscribe/Im5pZGhlZXNoQGFwcGFkb3JlLmNvbSI" ? null : (
        <div className="mt-5">
          <Container className={selected === "ar-AE" ? "rtl" : ""}>
            <Row>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="me-footer-block">
                  <img src={Logo} width="150" alt="logo" />

                  <ul
                    className={`me-footer-share ${
                      selected == "ar-AE" ? "pr-0" : ""
                    }`}
                  >
                    <li>
                      <a
                        href="https://www.facebook.com/flashatapp"
                        target="blank"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/flashatapp" target="blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/c/FlashatApp"
                        target="blank"
                      >
                        {" "}
                        <i className=" fab fa-youtube"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.instagram.com/flashatapp/"
                        target="blank"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://messejapp.tumblr.com/" target="blank">
                        <i class="fab fa-tumblr"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.snapchat.com/add/messejapp"
                        target="blank"
                      >
                        <i class="fab fa-snapchat"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="me-footer-block">
                  <h4>{HeaderContent?.header?.menu}</h4>
                  <ul className={selected === "ar-AE" ? "pr-0" : ""}>
                    <li style={{ listStyle: "none" }}>
                      <a href={`/${selected}`}>{HeaderContent?.header?.home}</a>
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <a href={`/${selected}/news`}>
                        {HeaderContent?.header?.news}
                      </a>
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <a href={`/${selected}/download`}>
                        {HeaderContent?.header?.download}
                      </a>
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <a href={`/${selected}/help-center`}>
                        {HeaderContent?.header?.helpCenter}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="me-footer-block">
                  <h4>{HeaderContent?.header?.policy}</h4>
                  <ul className={selected === "ar-AE" ? "pr-0" : ""}>
                    {Policies?.policyTypes?.Policy_category?.length
                      ? Policies?.policyTypes?.Policy_category?.map(
                          (policy) => (
                            <li style={{ listStyle: "none" }}>
                              <a
                                href={`/${selected}/policy/${policy?.policy_url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {policy?.policy_header}
                              </a>
                            </li>
                          )
                        )
                      : null}
                    {/* <li style={{ listStyle: "none" }}>
                      <a
                        href={`/${selected}/terms-of-use`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {HeaderContent?.header?.termsOfUse}{" "}
                      </a>
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <a
                        href={`/${selected}/username-guidelines`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {HeaderContent?.header?.usernameGuidelines}{" "}
                      </a>
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <a
                        href={`/${selected}/community-guidelines`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {HeaderContent?.header?.communityGuidelines}{" "}
                      </a>
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <a
                        href={`/${selected}/cookies-policy`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {HeaderContent?.header?.cookiesPolicy}{" "}
                      </a>
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <a
                        href={`/${selected}/huddle-policy`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {HeaderContent?.header?.huddlePolicy}{" "}
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="me-footer-block">
                  {HeaderContent?.header?.download && (
                    <h4>
                      {HeaderContent?.header?.download?.substr(0, 1) +
                        HeaderContent?.header?.download
                          ?.substr(1)
                          .toLowerCase()}
                    </h4>
                  )}
                  <ul className={selected === "ar-AE" ? "pr-0" : ""}>
                    <li style={{ listStyle: "none" }}>
                      <a href={`/${selected}/download`}>
                        {HeaderContent?.header?.android}
                      </a>
                    </li>
                    <li style={{ listStyle: "none" }}>
                      <Link
                        to={`/${selected}/download`}
                        onClick={() => window.scrollTo({ top: 600 })}
                      >
                        {/* {iPhone} */}

                        {HeaderContent?.header?.iphone}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Row>

            <p className="text-center my-5" style={{ color: "#" }}>
              {HeaderContent?.header?.copyright}
            </p>
          </Container>
        </div>
      )}
    </>
  );
};

export default Footer;
