import {
  GET_ACCOUNT_DELETE_BEGIN,
  GET_ACCOUNT_DELETE_FAIL,
  GET_ACCOUNT_DELETE_SUCCESS,
} from "./actionTypes"; // Update the import

export const initial_state = {
  account_delete: {},
  loading: false,
  error: "",
  firstLanguage: "",
};

const AccountDelete = (state = initial_state, action) => {
  switch (action.type) {
    case GET_ACCOUNT_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_ACCOUNT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        account_delete: action.payload,
        error: "",
      };

    case GET_ACCOUNT_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default AccountDelete;
