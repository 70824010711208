import {
  ADD_NEWS,
  ADD_NEWS_BEGIN,
  ADD_NEWS_FAIL,
  ADD_NEWS_SUCCESS,
  GET_NEWS_LIST,
  GET_NEWS_LIST_BEGIN,
  GET_NEWS_LIST_SUCCESS,
  GET_NEWS_LIST_FAIL,
  GET_NEWS,
  GET_NEWS_SUCCESS,
  GET_NEWS_BEGIN,
  GET_NEWS_FAIL,
  GET_LATEST_NEWS_FAIL,
  GET_LATEST_NEWS,
  GET_LATEST_NEWS_BEGIN,
  GET_LATEST_NEWS_SUCCESS,
  GET_NEWS_CATEGORY_BEGIN,
  GET_NEWS_CATEGORY_SUCCESS,
  GET_NEWS_CATEGORY_FAIL,
  GET_NEWS_CATEGORY,
} from "./actionTypes";

export const getNewsList = ({ request }) => ({
  type: GET_NEWS_LIST,
  request: request,
});

export const getNewsListBegin = () => ({
  type: GET_NEWS_LIST_BEGIN,
});

export const getNewsListSuccess = (news) => ({
  type: GET_NEWS_LIST_SUCCESS,
  payload: news,
});

export const getNewsListFail = (error) => ({
  type: GET_NEWS_LIST_FAIL,
  payload: error,
});

export const getLatestNewsList = ({ lang }) => ({
  type: GET_LATEST_NEWS,
  lang: lang,
});

export const getLatestNewsListBegin = () => ({
  type: GET_LATEST_NEWS_BEGIN,
});

export const getLatestNewsListSuccess = (news) => ({
  type: GET_LATEST_NEWS_SUCCESS,
  payload: news,
});

export const getLatestNewsListFail = (error) => ({
  type: GET_LATEST_NEWS_FAIL,
  payload: error,
});

export const getNews = ({ id, lang }) => ({
  type: GET_NEWS,
  id: id,
  lang: lang,
});

export const getNewsBegin = () => ({
  type: GET_NEWS_BEGIN,
});

export const getNewsSuccess = (news) => ({
  type: GET_NEWS_SUCCESS,
  payload: news,
});

export const getNewsFail = (error) => ({
  type: GET_NEWS_FAIL,
  payload: error,
});

export const getNewsCategory = ({ id }) => ({
  type: GET_NEWS_CATEGORY,
  id: id,
});

export const getNewsCategoryBegin = () => ({
  type: GET_NEWS_CATEGORY_BEGIN,
});

export const getNewsCategorySuccess = (categories) => ({
  type: GET_NEWS_CATEGORY_SUCCESS,
  payload: categories,
});

export const getNewsCategoryFail = (error) => ({
  type: GET_NEWS_CATEGORY_FAIL,
  payload: error,
});

export const addNews = (news) => ({
  type: ADD_NEWS,
  payload: news,
});

export const addNewsBegin = () => ({
  type: ADD_NEWS_BEGIN,
});

export const addNewsSuccess = (news) => ({
  type: ADD_NEWS_SUCCESS,
  payload: news,
});

export const addNewsFail = (error) => ({
  type: ADD_NEWS_FAIL,
  payload: error,
});
