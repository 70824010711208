import React, { useEffect, useState } from "react";
import Mobile1 from "../../assets/images/mobile-1.png";
import Mobile3 from "../../assets/images/mobile-3.png";
import Mobile3_1 from "../../assets/images/mobile-3-1.png";
import Mobile4_1 from "../../assets/images/mobile-4-1.png";
import Mobile6 from "../../assets/images/mobile-6.png";
import privateScreen from "../../assets/images/private_chat.png";
import Phone_landscape from "../../assets/images/phone-landscape.png";
import huddles from "../../assets/images/Huddles.png";
import privateChat from "../../assets/images/private.png";
import ChatScreen from "../../assets/images/chat_screen.png";

import { useDispatch, useSelector } from "react-redux";
import { getLatestNewsList, getNewsList } from "../../store/News/action";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import i18n from "i18next";

const Usage = ({ t, homeContent }) => {
  const dispatch = useDispatch();

  const [hovered, setHovered] = useState(false);
  const [id, setId] = useState(null);
  const newsDetails = useSelector((state) => state.News);
  const selected = localStorage?.getItem("i18nextLng");
  const HeaderContent = useSelector((state) => state.HeaderContent);
  console.log(HeaderContent?.header);
  const basicRequest = {
    page: 1,
    page_count: 6,
    keyword: "",
  };
  console.log("home content", homeContent);

  const location = useLocation();

  const history = useHistory();

  const [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    request &&
      dispatch(getNewsList({ request: { ...request, lang: selected } }));
  }, [request, selected]);

  const onMouseEnter = (id) => {
    setHovered(true);
    setId(id);
  };

  const onMouseLeave = (id) => {
    setHovered(false);
    setId(null);
  };

  return (
    <section
      id="why-use-this-app"
      className="why-use-this-app"
      style={{ paddingTop: "20px" }}
    >
      <div className="container">
        {homeContent?.section2?.enable ? (
          <div className="row row-two" style={{ paddingBottom: "80px" }}>
            <div className="col-lg-6">
              <div className="left-area content">
                <div className="img">
                  <img
                    className="img-fluid img1"
                    src={homeContent?.section2?.image1}
                    alt=""
                  />
                  <img
                    className="img-fluid img2"
                    src={homeContent?.section2?.image2}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex">
              <div className="right-area content">
                <div className="section-title extra-padding">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homeContent?.section2?.How_can_i_use_messej_App,
                    }}
                  ></div>
                  <p>{homeContent?.section2?.how_use_title}</p>
                </div>

                <p
                  style={{
                    fontSize: "18px",
                  }}
                >
                  <i> {homeContent?.section2?.Download_text}</i>{" "}
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {homeContent?.section3?.enable ? (
          <div className="row row-one">
            <div className="col-lg-6 d-flex align-self-center">
              <div className="left-area content">
                <div className="section-title extra-padding">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        homeContent?.section3
                          ?.Send_Broadcasts_To_Unlimited_Users_With,
                    }}
                  ></div>

                  <p>{homeContent?.section3?.broadcast_title}</p>
                </div>
                {homeContent?.section3.features[0].Feature != "" ? (
                  <p
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    <i>{homeContent?.section3?.features[0].Feature}</i>
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-lg-6 order-first order-lg-last">
              <div className="right-area">
                <div className="img">
                  <img
                    className="img-fluid img2"
                    src={homeContent?.section3?.image2}
                    alt=""
                  />
                  <img
                    className="img-fluid img1"
                    src={homeContent?.section3?.image1}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {homeContent?.section4?.enable ? (
          <div className="row row-two">
            <div className="col-lg-6">
              <div className="left-area content">
                <div className="img">
                  <img
                    className="img-fluid img1"
                    src={homeContent?.section4?.image2}
                    alt=""
                  />
                  <img
                    className="img-fluid img2"
                    src={homeContent?.section4?.image1}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-self-center">
              <div className="right-area content">
                <div className="section-title extra-padding">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homeContent?.section4?.Safe_and_Secure,
                    }}
                  ></div>
                  <p>{homeContent?.section4?.Safe_and_Secure_feature_1}</p>
                </div>
                {homeContent?.section4.features[0].Feature != "" ? (
                  <ul className="feature-list">
                    {homeContent?.section4?.features?.map((feature, index) => (
                      <li key={index}>
                        <p>
                          <i className="icofont-check"></i> {feature?.Feature}
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : null}
        {homeContent?.section5?.enable ? (
          <div
            // style={{
            //   height: "700px",
            // }}
            className="row row-three "
          >
            <div className="col-lg-6 d-flex align-self-center">
              <div className="left-area content">
                <div className="section-title extra-padding mb-4">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homeContent?.section5?.Your_in_messej_Biz,
                    }}
                  ></div>
                  <p className="mb-3">
                    {homeContent?.section5?.Your_in_messej_Biz_Heading}
                  </p>

                  <p>{homeContent?.section5?.Your_in_messej_Biz_Title}</p>
                </div>
                {homeContent?.section5.features[0].Feature != "" ? (
                  <ul className="feature-list mb-5">
                    {homeContent?.section5?.features?.map((feature, index) => (
                      <li key={index}>
                        <p>
                          <i className="icofont-check"></i> {feature?.Feature}
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
                {/* <div className="section-title extra-padding mb-3">
                  <p>{homeContent?.section5?.Your_in_messej_Biz_Title2}</p>
                </div>
                <ul className="feature-list mb-3">
                  {homeContent?.section5?.features2?.map((feature, index) => (
                    <li key={index}>
                      <p>
                        <i className="icofont-check"></i> {feature?.Feature}
                      </p>
                    </li>
                  ))}
                </ul> */}
              </div>
            </div>
            <div className="col-lg-6 align-self-center  order-first order-lg-last">
              <div className="right-area">
                <div className="img ">
                  <img
                    className="img-fluid img1 "
                    src={homeContent?.section5?.image1}
                    alt=""
                  />
                  <img
                    // width={260}
                    className="img-fluid img2 "
                    src={homeContent?.section5?.image2}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {homeContent?.section6?.enable ? (
          <div className="row row-two">
            <div className="col-lg-6">
              <div className="left-area content">
                <div className="img">
                  <img
                    className="img-fluid img1"
                    src={homeContent?.section6?.image2}
                    alt=""
                  />
                  <img
                    className="img-fluid img2"
                    // style={{ width: "250px" }}
                    src={homeContent?.section6?.image1}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-self-center">
              <div className="right-area content">
                <div className="section-title extra-padding">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homeContent?.section6?.Crackin,
                    }}
                  ></div>

                  <p>{homeContent?.section6?.Crackin_title}</p>
                </div>
                {homeContent?.section6.features[0].Feature != "" ? (
                  <ul className="feature-list">
                    {homeContent?.section6?.features?.map((feature, index) => (
                      <li key={index}>
                        <p>
                          <i className="icofont-check"></i> {feature?.Feature}
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : null}

        {homeContent?.section7?.enable ? (
          <div className="row row-three">
            <div className="col-lg-6 d-flex align-self-center">
              <div className="left-area content">
                <div className="section-title extra-padding mb-4">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homeContent?.section7?.Onrushin,
                    }}
                  ></div>
                  <p>{homeContent?.section7?.Onrushin_title}</p>
                </div>
                <ul className="feature-list  mb-5">
                  {homeContent?.section7?.features?.map((feature, index) => (
                    <li key={index}>
                      <p>
                        <i className="icofont-check"></i> {feature?.Feature}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-6 mt-2 order-first order-lg-last">
              <div className=" right-area">
                <div className="img">
                  {/* <img
                    className="img-fluid img1"
                    src={Phone_landscape}
                    alt=""
                  /> */}
                  <img
                    className="img-fluid img3"
                    style={{ width: "220px" }}
                    src={homeContent?.section7?.image1}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {newsDetails?.newsList?.length && !newsDetails?.loading ? (
          <div className="row row-two">
            <div className="">
              <div style={{ direction: "ltr" }}>
                <div className="section-title extra-padding">
                  <h2 className="title ml-4"> {HeaderContent?.header?.news}</h2>
                </div>
                <div className="dashboard-miniWidget-wrapper">
                  {newsDetails?.newsList?.length &&
                    newsDetails?.newsList?.slice(0, 4)?.map((news) => (
                      <div
                        className="my-4 col-6"
                        onMouseEnter={() => onMouseEnter(news.id)}
                        onMouseLeave={() => onMouseLeave(news.id)}
                        key={news.id}
                      >
                        <a
                          href={`${selected}/news-list/${news.news_id}`}
                          className="d-flex responsive-news-section"
                        >
                          <div className="mr-4">
                            <img
                              src={news.image_path}
                              alt="img"
                              className=" responsive-news-section-image"
                            />
                          </div>
                          <div className="news-contents-section">
                            <p
                              className="news-subsection font-weight-bold responsive-news-section-title mb-0"
                              style={
                                hovered && id === news.id
                                  ? { color: "#b227c1" }
                                  : {}
                              }
                            >
                              {`${
                                news.title.length > 50
                                  ? `${news.title.slice(0, 50)}...`
                                  : news.title
                              }`}
                            </p>
                            <h6 className="responsive-news-section-content">
                              {news?.content
                                ?.replace(/<\/?[^>]+(>|$)/g, "")
                                .slice(0, 102)}
                              ...
                            </h6>
                          </div>
                        </a>
                      </div>
                    ))}
                </div>
                <div className="d-flex flex-row-reverse mr-3">
                  <a href="/news" className="font-weight-bold view-all">
                    {HeaderContent?.header?.viewAll}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default Usage;
