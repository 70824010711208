import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { contactUs, contactUsCategory } from "../../store/ContactUs/actions";
import ConfirmationAlert from "../../components/confiramtionAlert";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Header from "../../components/header/navbar";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import i18n from "i18next";

const Contact = ({ dir, lang }) => {
  const contactUsData = useSelector((state) => state.ContactUs);
  const HeaderContent = useSelector((state) => state.HeaderContent);

  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const toggle = () => setModal(!modal);
  const [emptySubmit, setEmptySubmit] = useState(true);
  const dispatch = useDispatch();
  const [subject, setSubject] = useState("");

  const selected = localStorage?.getItem("i18nextLng");

  useEffect(() => {
    dispatch(contactUsCategory({ id: selected }));
  }, [selected]);

  useEffect(() => {
    contactUsData?.first_category && setSubject(contactUsData?.first_category);
  }, [contactUsData?.first_category]);

  useEffect(() => {
    if (!name || !email || !message || !EMAIL_REGEX.test(email)) {
      setEmptySubmit(true);
    } else {
      setEmptySubmit(false);
    }
  }, [name, email, message]);

  const EMAIL_REGEX = /^[^@]+@\w+(\.\w+)+\w$/;

  // handle contact us event
  const handleSubmit = (event) => {
    event.preventDefault();

    const messages = {
      name,
      email,
      subject,
      message,
    };

    // passing data to redux
    dispatch(
      contactUs({
        message: messages,
        toggle: () => toggle(),
      })
    );
    setEmail("");
    setMessage("");
    setName("");
    setSubject("GENERAL");
    setErrorMessage("");
  };

  useEffect(() => {
    if (email.length > 0 && !EMAIL_REGEX.test(email)) {
      setErrorMessage(HeaderContent?.header?.invalidEmail);
    } else {
      setErrorMessage("");
    }
  }, [email, selected]);

  const location = useLocation();

  // useEffect(() => {

  //   i18n.changeLanguage(location.pathname.slice(1, 6));
  // }, [location.pathname]);

  return (
    <>
      <Header direction={dir} language={lang} url="contact" />

      <div
        className="me-contact me-padder-top "
        data-testid="component-contact-us"
      >
        <Container>
          <h1 className="font-weight-bold mb-5 contact-align">
            {HeaderContent?.header?.contactUs}
          </h1>

          <Row>
            <div className="col-lg-7">
              <form onSubmit={handleSubmit}>
                <div className="me-contact-form">
                  <input
                    type="text"
                    placeholder={HeaderContent?.header?.name}
                    name="name"
                    value={name}
                    className="require "
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <select
                    className="col-12 contact-select"
                    value={subject}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                  >
                    {contactUsData?.categories?.subjects?.map((cat, index) => (
                      <option key={index}>{cat}</option>
                    ))}
                  </select>

                  <div>
                    <input
                      type="text"
                      placeholder={HeaderContent?.header?.email}
                      name="email"
                      id="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      className={errorMessage ? "mb-2" : ""}
                    />
                    {errorMessage && (
                      <p
                        className={`text-danger ml-1 ${
                          selected == "ar-AE" ? "errorMessage" : ""
                        }`}
                      >
                        {errorMessage}
                      </p>
                    )}
                  </div>

                  <textarea
                    placeholder={HeaderContent?.header?.message}
                    name="message"
                    id="message"
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    value={message}
                  ></textarea>
                  <button
                    type="submit"
                    className={`me-btn submitForm ${
                      emptySubmit ? "disable" : ""
                    }`}
                    disabled={emptySubmit ? true : false}
                  >
                    Submit
                  </button>
                  <div className="response"></div>
                </div>
              </form>
            </div>
          </Row>
        </Container>
        {contactUsData?.loading && <Loader />}
        <ConfirmationAlert
          modal_center={modal}
          setmodal_center={setModal}
          content={HeaderContent?.header?.verificationLink}
          toggle={toggle}
        />
      </div>
    </>
  );
};

export default Contact;
