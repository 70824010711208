import React, { useEffect, useRef, useState } from "react";
import Background from "../../assets/images/bg.png";
import Phone from "../../assets/images/mobile-4.png";
import iPhone from "../../assets/images/appstore.svg";
import android from "../../assets/images/playstore.svg";
import Header from "../../components/header/navbar";
import Steps from "./Steps";
import Usage from "./Usage";
import { useLocation } from "react-router-dom";
import i18n from "i18next";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getHomeContent } from "../../store/home/action";
import { base_URL } from "../../../src/helpers/api_helpers";
import axios from "axios";

const HomePage = ({ cookie }) => {
  const selected = localStorage?.getItem("i18nextLng");
  const dispatch = useDispatch();
  const homeContent = useSelector((state) => state.HomePage);
  console.log("home content", homeContent);

  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    dispatch(getHomeContent({ data: { lang: selected } }));
  }, [selected, location.pathname]);

  useEffect(() => {
    cookie &&
      axios
        .get(base_URL() + "/save_user_data")
        .then()
        .catch((er) => console.log(er));
  }, [cookie]);

  console.log("EST====", homeContent?.section3);

  return (
    <div data-testid="component-home">
      <Header home={true} url="" />
      {homeContent.loading && homeContent.home ? (
        <div className="blank"></div>
      ) : (
        <>
          <div className="me-main-wraper">
            <div
              className="d-flex justify-content-center px-auto"
              style={{
                marginTop: "150px",
                marginBottom: "-52px",
                color: "black",
                paddingRight: "105px",
                paddingLeft: "105px",
                textAlign: "center",
                fontStyle: "italic",
              }}
            >
              <p
                className="d-flex "
                style={{
                  fontSize: "35px",
                  // color: "black",
                  fontWeight: "lighter",
                  flexDirection: "column",
                }}
              >
                <span>
                  "Experience a thrilling expedition into the world of cloud{" "}
                </span>
                <span>
                  {" "}
                  connectivity with Flashat - Where You'll Discover Unlimited{" "}
                </span>
                <span> Communication and Seamless Interaction."</span>
              </p>
            </div>
            {homeContent?.home?.section1?.enable ? (
              <div id="home" className="hero-area area ">
                <div className="background"></div>
                <div className="container">
                  <div className="row justify-content-between">
                    <div className="col-lg-6 d-flex align-self-center">
                      <div className="left-content">
                        <div className="content">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                homeContent?.home?.section1
                                  ?.messej_Next_Level_Communication_App,
                            }}
                          ></div>

                          <p
                            className="subtitle"
                            style={{ textAlign: "justify" }}
                          >
                            {homeContent?.home?.section1?.messej_home_title}
                          </p>
                          <p
                            style={{
                              fontSize: "18px",
                            }}
                          >
                            <i>
                              "Flashat is an all-in-one social media platform
                              that offers secured private and public
                              interactions."
                            </i>
                          </p>
                          <div className="links">
                            <a
                              href="https://play.google.com/store/apps/details?id=com.app.messej&showAllReviews=true"
                              target="blank"
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={android}
                                className="mr-2 mb-2"
                                width="200px"
                                alt="android"
                              />
                            </a>
                            <a
                              href="https://apps.apple.com/in/app/messej/id1570210686"
                              target="blank"
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={iPhone}
                                className="mr-2 mb-2"
                                width="200px"
                                alt="iphone"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="right-area">
                        <div className="img">
                          <img
                            className="img-fluid full-img"
                            src={homeContent?.home?.section1?.image1}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="areas">
                  <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
                {/* <div id="learn_more"></div> */}
              </div>
            ) : null}
          </div>

          {/* <div id="learn_more">
            <Steps t={t} homeContent={homeContent?.home} />
          </div> */}
          <Usage t={t} homeContent={homeContent?.home} />
        </>
      )}
    </div>
  );
};

export default HomePage;
