import React from "react";
import logo from "../../assets/images/flashat-logo2.png";
import textlogo from "../../assets/images/flashat-logo-text.svg";
import android from "../../assets/images/Playstore.png";
import ios from "../../assets/images/App_store.png";
import tick from "../../assets/images/Web-page-UI.svg";

const Unsubscribe = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (
    <div className="SE-wrapper">
      <div className="SE-header">
        <a href="https://messej.com/">
          <img width={120} src={textlogo} alt="messej Logo" />
        </a>
      </div>
      <div className="SE-body">
        <div className="SE-top-section">
          <div className="SE-imageWrap">
            <img src={tick} alt="Success" />
          </div>
          <div className="SE-status-message success text-center">
            You have unsubscribed from our mail list
          </div>
          <div className="SE-status-content-wrap">
            <div className="SE-content">
              Follow our social media accounts to never miss out on any updates
            </div>
          </div>
        </div>
        <div className="SE-bottom-section">
          <div className="SE-bottom-logo-wrap align-center">
            <img width={70} src={logo} alt="Logo" />
          </div>
          <div className="downloadAppText align-center">Download App</div>
          <div className="SE-bottom-storeImg-wrap align-center">
            <a href="https://apps.apple.com/in/app/messej/id1570210686">
              <img src={ios} alt="App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.app.messej&showAllReviews=true">
              <img src={android} alt="Play Store" />
            </a>
          </div>
          <div className="copyright-desc">
            {`Copyright © ${getCurrentYear()}. All rights reserved - FLASHAT FOR SOCIAL MEDIA APPLICATIONS DEVELOPMENT & MANAGEMENT L.L.C`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unsubscribe;
