export const GETFAQ = "GETFAQ";
export const GETFAQ_BEGIN = "GETFAQ_BEGIN";
export const GETFAQ_SUCCESS = "GETFAQ_SUCCESS";
export const GETFAQ_FAIL = "GETFAQ_FAIL";

export const COUNT_FAQ_HELPFUL_RESPONSE = "COUNT_FAQ_HELPFUL_RESPONSE";
export const COUNT_FAQ_HELPFUL_RESPONSE_BEGIN =
  "COUNT_FAQ_HELPFUL_RESPONSE_BEGIN";
export const COUNT_FAQ_HELPFUL_RESPONSE_SUCCESS =
  "COUNT_FAQ_HELPFUL_RESPONSE_SUCCESS";
export const COUNT_FAQ_HELPFUL_RESPONSE_FAIL =
  "COUNT_FAQ_HELPFUL_RESPONSE_FAIL";

export const COUNT_FAQ_NOT_HELPFUL_RESPONSE = "COUNT_FAQ_NOT_HELPFUL_RESPONSE";
export const COUNT_FAQ_NOT_HELPFUL_RESPONSE_BEGIN =
  "COUNT_FAQ_NOT_HELPFUL_RESPONSE_BEGIN";
export const COUNT_FAQ_NOT_HELPFUL_RESPONSE_SUCCESS =
  "COUNT_FAQ_NOT_HELPFUL_RESPONSE_SUCCESS";
export const COUNT_FAQ_NOT_HELPFUL_RESPONSE_FAIL =
  "COUNT_FAQ_NOT_HELPFUL_RESPONSE_FAIL";
