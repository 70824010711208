import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../../assets/images/flashat-logo-text.svg";
import { Modal } from "reactstrap";

import i18n from "i18next";
import { useSelector } from "react-redux";

const Header = ({ home, url, languageToggle }) => {
  const data = useSelector((state) => state.Languages);
  const selected = localStorage?.getItem("i18nextLng");
  const trans = localStorage?.getItem("trans");
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const HeaderContent = useSelector((state) => state.HeaderContent);

  const history = useHistory();

  const [menuClass, setMenuClass] = useState("me-menu");
  const toggle = () => {
    setOpen(!open);
    open ? setMenuClass("me-menu") : setMenuClass("me-menu me-menu-open");
  };
  const location = useLocation();

  const homes = home ? true : false;

  useEffect(() => {
    if (selected == "ar-AE") {
      document.styleSheets[0].disabled = true;
      document.styleSheets[1].disabled = false;
    } else {
      document.styleSheets[1].disabled = true;
      document.styleSheets[0].disabled = false;
    }
  }, [selected]);

  useEffect(() => {
    if (
      location.pathname.length == 6 &&
      location.pathname.slice(1, 3).toLowerCase() == "en"
    ) {
      i18n.changeLanguage("en-US");
      return history.push("/en-US");
    }
    data?.languages?.length &&
      data?.languages?.map((dat) => {
        if (
          location.pathname.slice(1, 6).toLowerCase() ==
          dat?.lan_short_name.toLowerCase()
        ) {
          i18n.changeLanguage(location.pathname.slice(1, 6));
        }
      });
  }, [location.pathname]);

  useEffect(() => {
    trans == "true" && selected != "en-US"
      ? setShowModal(true)
      : setShowModal(false);
  }, [trans]);

  return (
    <div
      className={` align ${
        homes !== true ? "me-main-header" : "me-main-header fixed"
      }`}
    >
      {data?.languages?.length && (
        <Container>
          <Row>
            <Col sm={3} xs={7}>
              <div className="rtl-image">
                <a href={`/${selected}`}>
                  <img
                    src={Logo}
                    alt="logo"
                    className={
                      selected == "ar-AE" ? "logo-arabic mt-2" : "mt-2"
                    }
                    width="140px"
                    height="50px"
                  />
                </a>
              </div>
            </Col>
            <Col sm={9} xs={5}>
              <div className={menuClass}>
                <ul>
                  <li>
                    <a
                      href={`/${selected}`}
                      className={
                        location.pathname === `/${selected}`
                          ? "me-active-menu"
                          : ""
                      }
                    >
                      {HeaderContent?.header?.home}
                    </a>
                  </li>

                  <li>
                    <a
                      href={`/${selected}/news`}
                      className={
                        location.pathname === `/${selected}/news`
                          ? "me-active-menu"
                          : ""
                      }
                    >
                      {HeaderContent?.header?.news}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/${selected}/download`}
                      className={
                        location.pathname === `/${selected}/download`
                          ? "me-active-menu"
                          : ""
                      }
                    >
                      {HeaderContent?.header?.download}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/${selected}/help-center`}
                      className={
                        location.pathname === `/${selected}/help-center`
                          ? "me-active-menu"
                          : ""
                      }
                    >
                      {HeaderContent?.header?.helpCenter}
                    </a>
                  </li>

                  {/* <li>
                    <a
                      href={`/${selected}/account-delete`}
                      className={
                        location.pathname === `/${selected}/account-delete`
                          ? "me-active-menu"
                          : ""
                      }
                    >
                      ACCOUNT DELETE
                     
                    </a>
                  </li> */}

                  {!languageToggle && (
                    <li className="py-3">
                      <select
                        className="form-scroll-select"
                        onChange={(e) => {
                          localStorage.setItem("trans", true);
                          !url
                            ? history.push(e.target.value)
                            : history.push(`/${e.target.value}/${url}`);

                          i18n.changeLanguage(e.target.value);
                        }}
                      >
                        {data?.languages?.length &&
                          data?.languages?.map((lang) =>
                            selected === lang.lan_short_name ? (
                              <option
                                id={lang.id}
                                key={lang.id}
                                selected
                                value={lang.lan_short_name}
                                name={lang.lan_short_name}
                              >
                                {lang.lan_name}
                              </option>
                            ) : (
                              <option
                                key={lang.id}
                                id={lang.id}
                                value={lang.lan_short_name}
                                name={lang.lan_short_name}
                              >
                                {lang.lan_name}
                              </option>
                            )
                          )}
                      </select>
                    </li>
                  )}
                </ul>

                <div className="me-toggle-nav" onClick={toggle}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </Col>

            <Modal isOpen={showModal} centered={true}>
              <div className="modal-header" style={{ direction: "ltr" }}>
                <h5 className="modal-title mt-0">
                  Language Translation Disclaimer
                </h5>
              </div>
              <div className="modal-body" style={{ direction: "ltr" }}>
                Language Translations on Flashat App and Websites are translated
                using third party translation Services such as “Google
                Translate”. Even Though reasonable efforts are made to provide
                accurate translations, some portions may be incorrect, some
                phrases may mean differently due to the use of automatic
                translation services and language restrictions. Flashat will not
                be liable for any errors or omissions in the language
                translations provided in App or Websites. We can only verify the
                validity and accuracy of information provided in the English
                language. Any person that relies on translated contents does so
                at their own risk as we do not make any promises,assurances or
                guarantees of the translation provided and Flashat will not be
                liable for any loss or damage occurred to you by using
                translated contents. In case you find any translation error we
                request you to inform us the same by contacting at
                info@flashat.com
              </div>
              <div className="modal-footer" style={{ direction: "ltr" }}>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => {
                    setShowModal(false);
                    localStorage.setItem("trans", false);
                  }}
                >
                  OK
                </button>
              </div>
            </Modal>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default Header;
