import {
  COUNT_FAQ_HELPFUL_RESPONSE_BEGIN,
  COUNT_FAQ_HELPFUL_RESPONSE_FAIL,
  COUNT_FAQ_HELPFUL_RESPONSE_SUCCESS,
  COUNT_FAQ_NOT_HELPFUL_RESPONSE_BEGIN,
  COUNT_FAQ_NOT_HELPFUL_RESPONSE_FAIL,
  COUNT_FAQ_NOT_HELPFUL_RESPONSE_SUCCESS,
  GETFAQ_BEGIN,
  GETFAQ_FAIL,
  GETFAQ_SUCCESS,
} from "./actionType";

const initial_state = {
  faq: {},
  loading: false,
  error: "",
  faqResponseLoading: false,
};

const FAQ = (state = initial_state, action) => {
  switch (action.type) {
    case GETFAQ_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GETFAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        faq: action.payload,
        error: "",
      };
    case GETFAQ_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case COUNT_FAQ_HELPFUL_RESPONSE_BEGIN:
      return {
        ...state,
        faqResponseLoading: true,
      };
    case COUNT_FAQ_HELPFUL_RESPONSE_SUCCESS:
      return {
        ...state,
        faqResponseLoading: false,
        error: "",
      };
    case COUNT_FAQ_HELPFUL_RESPONSE_FAIL:
      return {
        ...state,
        faqResponseLoading: false,
        error: action.payload,
      };

    case COUNT_FAQ_NOT_HELPFUL_RESPONSE_BEGIN:
      return {
        ...state,
        faqResponseLoading: true,
      };
    case COUNT_FAQ_NOT_HELPFUL_RESPONSE_SUCCESS:
      return {
        ...state,
        faqResponseLoading: false,
        error: "",
      };
    case COUNT_FAQ_NOT_HELPFUL_RESPONSE_FAIL:
      return {
        ...state,
        faqResponseLoading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default FAQ;
