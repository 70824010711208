import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../helpers/api_helpers";
import {
  getLegalDocumentBegin,
  getLegalDocumentFail,
  getLegalDocumentSuccess,
  getLegalDocumentTypeBegin,
  getLegalDocumentTypeFail,
  getLegalDocumentTypeSuccess,
} from "./action";
import { GET_LEGAL_DOCUMENT, GET_LEGAL_DOCUMENT_TYPE } from "./actionTypes";

function* getLegalDocument({ data }) {
  try {
    yield put(getLegalDocumentBegin());
    let response = yield call(get, `/get_policy`, data);
    if (response) {
      yield put(getLegalDocumentSuccess(response.result));
    }
  } catch (error) {
    yield put(getLegalDocumentFail());
  }
}

function* getLegalDocumentType(data) {
  try {
    console.log(data);
    yield put(getLegalDocumentTypeBegin());
    let response = yield call(get, "/list_policies", data?.data);
    if (response) {
      yield put(getLegalDocumentTypeSuccess(response.result));
    }
  } catch (error) {
    yield put(getLegalDocumentTypeFail());
  }
}

export function* policySaga() {
  yield takeEvery(GET_LEGAL_DOCUMENT, getLegalDocument);
  yield takeEvery(GET_LEGAL_DOCUMENT_TYPE, getLegalDocumentType);
}
