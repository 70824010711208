import {
  CONTACT_US_BEGIN,
  CONTACT_US_FAIL,
  CONTACT_US_SUCCESS,
  GET_CONTACT_US_CATEGORY_BEGIN,
  GET_CONTACT_US_CATEGORY_FAIL,
  GET_CONTACT_US_CATEGORY_SUCCESS,
} from "./actionTypes";

export const initialState = {
  error: "",
  loading: false,
  success: "",
  categories: {},
  first_category: "",
};

const ContactUs = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_US_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
      };

    case CONTACT_US_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case GET_CONTACT_US_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_CONTACT_US_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload,
        categories: action.payload,
        first_category: action.payload?.subjects[0],
      };

    case GET_CONTACT_US_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    default:
      return { ...state };
  }
};

export default ContactUs;
