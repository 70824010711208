import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLatestNewsList } from "../../store/News/action";
import { Link } from "react-router-dom";

const RecentPosts = (props) => {
  const dispatch = useDispatch();

  const [hovered, setHovered] = useState(false);
  const [id, setId] = useState(null);

  const onMouseEnter = (id) => {
    setHovered(true);
    setId(id);
  };

  const onMouseLeave = (id) => {
    setHovered(false);
    setId(null);
  };

  useEffect(() => {
    dispatch(getLatestNewsList({ lang: props.selected }));
  }, [dispatch, props.selected]);

  const newsDetails = useSelector((state) => state.News);

  const newsList =
    newsDetails?.latestNewsList?.length &&
    newsDetails?.latestNewsList?.filter(
      (news) => news.news_id !== props.currentNews
    );

  return (
    <>
      <ul className="mt-5 recent-post-responsive">
        {!newsDetails?.loading && newsList?.length
          ? newsList?.length &&
            newsList?.map((news) => (
              <li
                style={{ listStyle: "none" }}
                key={news.news_id}
                className="single-post-responsive"
              >
                <a
                  href={`/${props.selected}/news-list/${news.news_id}`}
                  onMouseEnter={() => onMouseEnter(news.news_id)}
                  onMouseLeave={() => onMouseLeave(news.news_id)}
                >
                  <div className="d-flex recent-post">
                    <p
                      className="news-section ml-2 text-lowercase"
                      style={
                        hovered && id === news.news_id
                          ? { color: "#b227c1" }
                          : { color: "#333" }
                      }
                    >
                      {`${
                        news.title.length > 55
                          ? `${news.title.slice(0, 55)}...`
                          : news.title
                      }`}
                      <br />
                      {/* <span className="news-date">{news.published_date}</span> */}
                    </p>
                    <div>
                      <img
                        src={news.image_path}
                        alt="img"
                        className={
                          props.selected == "en-US"
                            ? "mb-1 recent-img-responsive"
                            : "mb-1 recent-img-responsive"
                        }
                      />
                    </div>
                  </div>
                </a>
              </li>
            ))
          : newsList?.length && !newsDetails?.loading
          ? newsList?.map((news) => (
              <li
                style={{ listStyle: "none" }}
                key={news.news_id}
                className="single-post-responsive"
              >
                <a
                  href={`/${props.selected}/news-list/${news.news_id}`}
                  onMouseEnter={() => onMouseEnter(news.news_id)}
                  onMouseLeave={() => onMouseLeave(news.news_id)}
                >
                  <div className="d-flex recent-post">
                    <p
                      className="news-section "
                      style={
                        hovered && id === news.news_id
                          ? { color: "#b227c1" }
                          : { color: "#333" }
                      }
                    >
                      {`${
                        news.title.length > 55
                          ? `${news.title.slice(0, 55)}...`
                          : news.title
                      }`}
                      <br />
                    </p>
                  </div>
                </a>
              </li>
            ))
          : null}
      </ul>
    </>
  );
};

export default RecentPosts;
