import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./locale/en/translation.json";
const fallbackLng = ["en-SA"];

const resources = {
  "en-US": {
    translation: translationEN,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true,
    },
    debug: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
