export const GET_NEWS_LIST = "GET_NEWS_LIST";
export const GET_NEWS_LIST_BEGIN = "GET_NEWS_LIST_BEGIN";
export const GET_NEWS_LIST_SUCCESS = "GET_NEWS_LIST_SUCCESS";
export const GET_NEWS_LIST_FAIL = "GET_NEWS_LIST_FAIL";

export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_BEGIN = "GET_NEWS_BEGIN";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_FAIL = "GET_NEWS_FAIL";

export const GET_NEWS_CATEGORY = "GET_NEWS_CATEGORY";
export const GET_NEWS_CATEGORY_BEGIN = "GET_NEWS_CATEGORY_BEGIN";
export const GET_NEWS_CATEGORY_SUCCESS = "GET_NEWS_CATEGORY_SUCCESS";
export const GET_NEWS_CATEGORY_FAIL = "GET_NEWS_CATEGORY_FAIL";

export const GET_LATEST_NEWS = "GET_LATEST_NEWS";
export const GET_LATEST_NEWS_BEGIN = "GET_LATEST_NEWS_BEGIN";
export const GET_LATEST_NEWS_SUCCESS = "GET_LATEST_NEWS_SUCCESS";
export const GET_LATEST_NEWS_FAIL = "GET_LATEST_NEWS_FAIL";

export const ADD_NEWS = "ADD_NEWS";
export const ADD_NEWS_BEGIN = "ADD_NEWS_BEGIN";
export const ADD_NEWS_SUCCESS = "ADD_NEWS_SUCCESS";
export const ADD_NEWS_FAIL = "ADD_NEWS_FAIL";
