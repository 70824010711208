import { combineReducers } from "redux";
import ContactUs from "./ContactUs/reducer";
import News from "./News/reducer";
import FAQ from "./FAQ/reducer";
import Policies from "./Policies/reducer";
import HomePage from "./home/reducer";
import Languages from "./languages/reducers";
import DownloadContent from "./download/reducer";
import HeaderContent from "./headerandfooter/reducer";
import AccountDelete from "./accountDelete/reducer";

const rootReducer = combineReducers({
  ContactUs,
  News,
  FAQ,
  Policies,
  HomePage,
  Languages,
  DownloadContent,
  HeaderContent,
  AccountDelete,
});

export default rootReducer;
