import React from "react";
import Playstore from "../../assets/images/download.png";
import Appstore from "../../assets/images/app-store.png";
// import Android from "../../assets/images/android.png";
import Android from "../../assets/images/flashat-android-web-mockup.png";
// import IOS from "../../assets/images/ios.png";
import IOS from "../../assets/images/flashat-ios-web-mockup.png";
import Header from "../../components/header/navbar";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { getDownloadContent } from "../../store/download/action";

const Download = ({ dir, lang }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const location = useLocation();
  const selected = localStorage?.getItem("i18nextLng");

  const downloadData = useSelector((state) => state.DownloadContent);
  console.log("download data", downloadData);

  useEffect(() => {
    dispatch(getDownloadContent({ data: { lang: selected } }));
  }, [selected, location.pathname]);

  // useEffect(() => {
  //   i18n.changeLanguage(location.pathname.slice(1, 6));
  // }, [location.pathname]);

  return (
    <>
      <Header direction={dir} language={lang} url="download" />
      <section
        className="why-use-this-app me-blog-grid me-padder-top"
        style={{ paddingBottom: 0 }}
      >
        <div className="container">
          {/* <h1 className="font-weight-bold mb-5">
            {" "}
            {downloadData?.download?.download_heading}
          </h1> */}

          <div className="row row-one">
            <div className="col-lg-6 d-flex align-self-center">
              <div className="left-area content">
                <div className="section-title extra-padding">
                  <h2 className="title">
                    {" "}
                    {downloadData?.download?.android_heading}
                  </h2>
                  <p> {downloadData?.download?.android_content}</p>
                </div>
                <div className="img">
                  <a
                    className="responsive-andr-anchor"
                    href={downloadData?.download?.android_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ cursor: "pointer" }}
                  >
                    <img className="img-fluid img1" src={Playstore} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-first order-lg-last">
              <div className="right-area">
                <div className="img">
                  <img
                    key={downloadData?.download?.image1}
                    src={downloadData?.download?.image1}
                    alt="android"
                    style={{ maxWidth: "500px", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row row-two">
            <div className="col-lg-6 ">
              <div className="left-area content">
                <div className="img text-center">
                  <img
                    className="img-fluid img1"
                    src={downloadData?.download?.image2}
                    alt="ios"
                    style={{ maxWidth: "500px" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-self-center appstore-container-responsive">
              <div className="left-area content">
                <div className="section-title extra-padding">
                  <h2 className="title">
                    {downloadData?.download?.ios_heading}
                  </h2>
                  <p>{downloadData?.download?.ios_content}</p>
                </div>
                <div className="img mt-5 appstore-responsive">
                  <a
                    className="responsive-ios-anchor"
                    href={downloadData?.download?.ios_link}
                    style={{ cursor: "pointer" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="img-fluid img2" src={Appstore} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Download;
