import {
  ADD_HOME_CONTENT,
  ADD_HOME_CONTENT_BEGIN,
  ADD_HOME_CONTENT_FAIL,
  ADD_HOME_CONTENT_SUCCESS,
  GET_HOME_CONTENT,
  GET_HOME_CONTENT_BEGIN,
  GET_HOME_CONTENT_FAIL,
  GET_HOME_CONTENT_SUCCESS,
} from "./actionTypes";

export const getHomeContent = ({ data }) => ({
  type: GET_HOME_CONTENT,
  data: data,
});

export const getHomeContentBegin = () => ({
  type: GET_HOME_CONTENT_BEGIN,
});

export const getHomeContentSuccess = (language) => ({
  type: GET_HOME_CONTENT_SUCCESS,
  payload: language,
});

export const getHomeContentFail = (error) => ({
  type: GET_HOME_CONTENT_FAIL,
  payload: error,
});

export const addHomeContent = ({ data, id }) => ({
  type: ADD_HOME_CONTENT,
  data: data,
  id: id,
});

export const addHomeContentBegin = () => ({
  type: ADD_HOME_CONTENT_BEGIN,
});

export const addHomeContentSuccess = (language) => ({
  type: ADD_HOME_CONTENT_SUCCESS,
  payload: language,
});

export const addHomeContentFail = (error) => ({
  type: ADD_HOME_CONTENT_FAIL,
  payload: error,
});
