import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Row } from "reactstrap";
import Header from "../../../components/header/navbar";
import { getNews } from "../../../store/News/action";
import RecentPosts from "../RecentPosts";
import ReactHtmlParser from "react-html-parser";
import Pages404 from "../../utility/pages-404";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import i18n from "i18next";

const NewsDetails = (props) => {
  const newsDetails = useSelector((state) => state.News);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const HeaderContent = useSelector((state) => state.HeaderContent);

  const selected = localStorage.getItem("i18nextLng") || "en-US";

  const match = useParams();

  useEffect(() => {
    dispatch(getNews({ id: match.id, lang: selected }));
  }, [match.id, selected]);

  useEffect(() => {
    const scroll = document?.querySelector(".scroll");

    window.addEventListener("scroll", () => {
      scroll?.classList?.toggle("active", window.scrollY > 200);
    });

    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    !newsDetails.error &&
      scroll?.addEventListener("click", () => {
        scrollToTop();
      });
  }, []);

  const html = newsDetails?.news?.content;

  const location = useLocation();

  return (
    <>
      {newsDetails?.loading && <div className="animated yt-loader"></div>}

      {!newsDetails.newsDetailsError ? (
        <>
          <Header url={`news-list/${match.id}`} />
          <div className="me-blog-single me-padder-top">
            <div className="container">
              {newsDetails?.news ? (
                <div className="row">
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="me-blog-box me-blog-date">
                          <span className="text-weight-bold ">
                            {newsDetails?.news?.published_date &&
                              new Date(
                                `${newsDetails?.news?.published_date} UTC`
                              )
                                .toString()
                                .slice(3, 21)}
                          </span>
                          <h3
                            className="me-blog-title mb-4"
                            style={{ lineHeight: "2.5rem" }}
                          >
                            {newsDetails?.news?.title}
                          </h3>
                          <div className="me-blog-img">
                            {newsDetails?.news?.image_path && (
                              <img
                                src={newsDetails?.news?.image_path}
                                alt="img"
                                className="img-fluid mb-5"
                                width="825px"
                                height="400px"
                              />
                            )}
                          </div>
                          <div className="me-blog-single-content">
                            <div className="me-blog-content-body">
                              <div>{ReactHtmlParser(html)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="me-blog-sidebar">
                      <div className="me-widget me-widget-recent-post">
                        {newsDetails?.latestNewsList?.length > 1 ? (
                          <h4 className="me-widget-title title-rtl">
                            {HeaderContent?.header?.recentPosts}
                          </h4>
                        ) : null}
                        <RecentPosts
                          selected={selected}
                          currentNews={newsDetails?.news?.news_id}
                          direction={props.dir}
                          language={props.lang}
                          url={`news-list/${match.id}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="blank"></div>
              )}
            </div>
          </div>
        </>
      ) : (
        <Pages404 />
      )}
    </>
  );
};

export default NewsDetails;
