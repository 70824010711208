import {
  GET_LEGAL_DOCUMENT_BEGIN,
  GET_LEGAL_DOCUMENT_FAIL,
  GET_LEGAL_DOCUMENT_SUCCESS,
  GET_LEGAL_DOCUMENT_TYPE_BEGIN,
  GET_LEGAL_DOCUMENT_TYPE_FAIL,
  GET_LEGAL_DOCUMENT_TYPE_SUCCESS,
} from "./actionTypes";

export const init_state = {
  policyData: {},
  error: false,
  loading: false,
  policyTypes: "",
};

const Policy = (state = init_state, action) => {
  switch (action.type) {
    case GET_LEGAL_DOCUMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_LEGAL_DOCUMENT_SUCCESS:
      return {
        ...state,
        policyData: action.payload,
        loading: false,
        error: "",
      };

    case GET_LEGAL_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_LEGAL_DOCUMENT_TYPE_BEGIN:
      return {
        ...state,
        loading: true,
        policyTypes: "",
      };

    case GET_LEGAL_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        policyTypes: action.payload,
        loading: false,
        error: "",
      };

    case GET_LEGAL_DOCUMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        policyTypes: "",
      };

    default:
      return {
        ...state,
      };
  }
};

export default Policy;
