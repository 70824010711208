import { useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import Contact from "./pages/Contact";
import FaqPage from "./pages/Faq";
import HomePage from "./pages/Home";
import NewsRoom from "./pages/NewsRoom";
import NewsDetails from "./pages/NewsRoom/NewsDetails/index";
import Pages404 from "./pages/utility/pages-404";
import Footer from "../src/components/footer/footer";
import Download from "./pages/Download";
import { useDispatch, useSelector } from "react-redux";
import { getLanguages } from "./store/languages/action";
import { useCookies } from "react-cookie";
import axios from "axios";
import { getHeaderContent } from "./store/headerandfooter/action";
import Unsubscribe from "./pages/Unsubscribe";
import Policy from "./pages/Policies/Policy";
import { base_URL } from "../src/helpers/api_helpers";
import AccountDelete from "./pages/AccountDelete";

const App = () => {
  const [cookies, setCookie] = useCookies(["cookie_notice_accepted"]);
  const selected = localStorage?.getItem("i18nextLng") || "en-US";
  const data = useSelector((state) => state.HeaderContent);
  const dispatch = useDispatch();
  useEffect(() => {
    const scroll = document.querySelector(".scroll");

    window?.addEventListener("scroll", () => {
      scroll?.classList.toggle(
        `${
          cookies?.cookie_notice_accepted == undefined ? "active" : "actives"
        }`,
        window.scrollY > 200
      );
    });

    function scrollToTop() {
      window?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    scroll.addEventListener("click", () => {
      scrollToTop();
    });
  }, [cookies?.cookie_notice_accepted]);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname.includes("Im5pZGhlZXNoQGFwcGFkb3JlLmNvbSI")) {
      history.push({
        pathname: "/unsubscribe/Im5pZGhlZXNoQGFwcGFkb3JlLmNvbSI",
        search: "",
      });
    }
  }, []);

  const LanguageData = useSelector((state) => state.Languages);

  useEffect(() => {
    dispatch(getLanguages());
    dispatch(getHeaderContent({ data: { lang: selected } }));
  }, [selected, location.pathname]);

  useEffect(() => {
    if (location.pathname === `/${selected}`) {
      document.title = "Flashat";
    } else if (
      !data?.loading &&
      location.pathname === `/${selected}/help-center`
    ) {
      document.title =
        data?.header?.helpCenter != undefined
          ? "Flashat " + data?.header?.helpCenter?.toLowerCase()
          : "Flashat";
    } else if (location.pathname === `/${selected}/news`) {
      document.title =
        data?.header?.news != undefined
          ? " Flashat " + data?.header?.news?.toLowerCase()
          : "Flashat";
    } else if (location.pathname === `/${selected}/download`) {
      document.title =
        data?.header?.download != undefined
          ? data?.header?.download?.toLowerCase() + " Flashat "
          : "Flashat";
    } else if (location.pathname === `/${selected}/contact`) {
      document.title =
        data?.header?.contactUs != undefined
          ? "Flashat " + data?.header?.contactUs?.toLowerCase()
          : "Flashat";
    } else if (location.pathname.slice(1, 16) === `${selected}/news-list`) {
      document.title =
        data?.header?.news != undefined
          ? "Flashat " + data?.header?.news?.toLowerCase()
          : "Flashat";
    } else if (location.pathname === `/${selected}/privacy-policy`) {
      document.title =
        data?.header?.privacyPolicy != undefined
          ? "Flashat " + data?.header?.privacyPolicy?.toLowerCase()
          : "Flashat";
    } else if (location.pathname === `/${selected}/terms-of-use`) {
      document.title =
        data?.header?.termsOfUse != undefined
          ? "Flashat " + data?.header?.termsOfUse?.toLowerCase()
          : "Flashat";
    } else if (location.pathname === `/${selected}/username-guidelines`) {
      document.title =
        data?.header?.usernameGuidelines != undefined
          ? "Flashat " + data?.header?.usernameGuidelines?.toLowerCase()
          : "Flashat";
    } else if (location.pathname === `/${selected}/community-guidelines`) {
      document.title =
        data?.header?.communityGuidelines != undefined
          ? "Flashat " + data?.header?.communityGuidelines?.toLowerCase()
          : "Flashat";
    } else if (location.pathname === `/${selected}/cookies-policy`) {
      document.title =
        data?.header?.cookiesPolicy != undefined
          ? "Flashat " + data?.header?.cookiesPolicy?.toLowerCase()
          : "Flashat";
    } else if (location.pathname === `/${selected}/california-privacy-notice`) {
      document.title =
        data?.header?.californiaPrivacy != undefined
          ? "Flashat " + data?.header?.californiaPrivacy?.toLowerCase()
          : "Flashat";
    } else if (location.pathname === `/${selected}/brazil-privacy-notice`) {
      document.title =
        data?.header?.brazilPolicy != undefined
          ? "Flashat " + data?.header?.brazilPolicy?.toLowerCase()
          : "Flashat";
    } else if (location.pathname === `/${selected}/performance-point`) {
      document.title =
        data?.header?.performancePoints != undefined
          ? "Flashat " + data?.header?.performancePoints?.toLowerCase()
          : "Flashat";
    } else if (location.pathname === `/${selected}/huddle-policy`) {
      document.title =
        data?.header?.performancePoints != undefined
          ? "Flashat " + data?.header?.performancePoints?.toLowerCase()
          : "Flashat";
    } else if (location.pathname === `/${selected}/account-delete`) {
      document.title =
        data?.header?.performancePoints != undefined
          ? "Flashat " + "account delete"
          : "Flashat";
    }
  }, [location.pathname, data?.loading]);

  // useEffect(() => {
  //   i18n.changeLanguage();
  // }, [location.pathname, selected]);

  const handle = () => {
    axios.get(base_URL() + "/save_user_data").then(() =>
      setCookie("cookie_notice_accepted", true, {
        maxAge: 2592000,
        path: "/",
      })
    );
  };

  return (
    <div className="me-main-wrapper">
      <div className="scroll">
        <i className="fa fa-chevron-right top"></i>
      </div>
      {!LanguageData?.languages?.length && LanguageData?.loading && (
        <div className="blank"></div>
      )}

      {!cookies.cookie_notice_accepted &&
      location.pathname !== "/unsubscribe/Im5pZGhlZXNoQGFwcGFkb3JlLmNvbSI" ? (
        <div className="cookie d-flex fade-in">
          <p className=" mr-4">
            <b>We Need Your Kind Consent </b>
            <br />
            In order to serve our users better, Flashat website uses cookies to
            personalize your browsing experience. Accordingly we would like to
            obtain your consent to the use of cookies. For more information,
            Read our
            <a
              className="ml-1 text-white"
              style={{ textDecoration: "underline" }}
              href="/en-US/policy/cookies-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookies Policies.
            </a>
          </p>
          <button className="btn cookie-btn mr-2 mt-2" onClick={handle}>
            I AGREE
          </button>
        </div>
      ) : null}

      <Switch>
        {/* Home  */}
        <Route exact path="/" render={() => <Redirect to={`/${selected}`} />} />

        {LanguageData?.languages.length &&
          LanguageData?.languages?.map((lang) => (
            <Route
              key={lang.id}
              exact
              path={`/${lang.lan_short_name}`}
              render={() => (
                <HomePage cookie={cookies.cookie_notice_accepted} />
              )}
            />
          ))}

        {/* FAQ */}
        <Route
          exact
          path="/help-center"
          render={() => <Redirect to={`/${selected}/help-center`} />}
        />

        {LanguageData?.languages.length &&
          LanguageData?.languages?.map((lang) => (
            <Route
              key={lang.id}
              exact
              path={`/${lang.lan_short_name}/help-center`}
              render={() => <FaqPage cookie={cookies.cookie_notice_accepted} />}
            />
          ))}

        {/* account delete */}
        <Route
          exact
          path="/account-delete"
          render={() => <Redirect to={`/${selected}/account-delete`} />}
        />

        {LanguageData?.languages.length &&
          LanguageData?.languages?.map((lang) => (
            <Route
              key={lang.id}
              exact
              path={`/${lang.lan_short_name}/account-delete`}
              render={() => (
                <AccountDelete cookie={cookies.cookie_notice_accepted} />
              )}
            />
          ))}

        {/* News  */}

        <Route
          exact
          path="/news"
          render={() => <Redirect to={`/${selected}/news`} />}
        />

        {LanguageData?.languages.length &&
          LanguageData?.languages?.map((lang) => (
            <Route
              key={lang.id}
              exact
              path={`/${lang.lan_short_name}/news`}
              render={() => (
                <NewsRoom cookie={cookies.cookie_notice_accepted} />
              )}
            />
          ))}

        {/* Download  */}
        <Route
          exact
          path="/download"
          render={() => <Redirect to={`/${selected}/download`} />}
        />

        {LanguageData?.languages.length &&
          LanguageData?.languages?.map((lang) => (
            <Route
              key={lang.id}
              exact
              path={`/${lang.lan_short_name}/download`}
              render={() => <Download />}
            />
          ))}

        {/* Contact  */}
        <Route
          exact
          path="/contact"
          render={() => <Redirect to={`/${selected}/contact`} />}
        />

        {LanguageData?.languages.length &&
          LanguageData?.languages?.map((lang) => (
            <Route
              key={lang.id}
              exact
              path={`/${lang.lan_short_name}/contact`}
              render={() => <Contact />}
            />
          ))}

        {/* News details */}
        <Route
          exact
          path="/news-list/:id"
          render={() => <Redirect to={`/${selected}/news-list/:id`} />}
        />

        {LanguageData?.languages.length &&
          LanguageData?.languages?.map((lang) => (
            <Route
              key={lang.id}
              exact
              path={`/${lang.lan_short_name}/news-list/:id`}
              render={() => <NewsDetails />}
            />
          ))}

        <Route
          exact
          path="/policy/:id"
          render={() => <Redirect to={`/${selected}/policy/:id`} />}
        />

        {LanguageData?.languages.length &&
          LanguageData?.languages?.map((lang) => (
            <Route
              key={lang.id}
              exact
              path={`/${lang.lan_short_name}/policy/:id`}
              render={() => <Policy />}
            />
          ))}

        <Route
          exact
          path={`/unsubscribe/Im5pZGhlZXNoQGFwcGFkb3JlLmNvbSI`}
          component={Unsubscribe}
        />

        {LanguageData?.languages?.length && !LanguageData?.loading ? (
          <Route exact path="*" component={Pages404} />
        ) : null}
      </Switch>
      {LanguageData?.languages?.length && !LanguageData?.loading && (
        <Footer location={location.pathname} />
      )}
    </div>
  );
};

export default App;
