import {
  GET_LEGAL_DOCUMENT,
  GET_LEGAL_DOCUMENT_BEGIN,
  GET_LEGAL_DOCUMENT_FAIL,
  GET_LEGAL_DOCUMENT_SUCCESS,
  GET_LEGAL_DOCUMENT_TYPE,
  GET_LEGAL_DOCUMENT_TYPE_BEGIN,
  GET_LEGAL_DOCUMENT_TYPE_FAIL,
  GET_LEGAL_DOCUMENT_TYPE_SUCCESS,
} from "./actionTypes";

export const getLegalDocument = ({ data }) => ({
  type: GET_LEGAL_DOCUMENT,
  data: data,
});

export const getLegalDocumentBegin = () => ({
  type: GET_LEGAL_DOCUMENT_BEGIN,
});

export const getLegalDocumentSuccess = (data) => ({
  type: GET_LEGAL_DOCUMENT_SUCCESS,
  payload: data,
});

export const getLegalDocumentFail = (error) => ({
  type: GET_LEGAL_DOCUMENT_FAIL,
  payload: error,
});

export const getLegalDocumentType = (data) => ({
  type: GET_LEGAL_DOCUMENT_TYPE,
  data: data,
});

export const getLegalDocumentTypeBegin = () => ({
  type: GET_LEGAL_DOCUMENT_TYPE_BEGIN,
});

export const getLegalDocumentTypeSuccess = (data) => ({
  type: GET_LEGAL_DOCUMENT_TYPE_SUCCESS,
  payload: data,
});

export const getLegalDocumentTypeFail = (error) => ({
  type: GET_LEGAL_DOCUMENT_TYPE_FAIL,
  payload: error,
});
