import {
  GET_DOWNLOAD_CONTENT_BEGIN,
  GET_DOWNLOAD_CONTENT_FAIL,
  GET_DOWNLOAD_CONTENT_SUCCESS,
} from "./actionTypes";

export const initial_state = {
  download: {},
  loading: false,
  error: "",
  firstLanguage: "",
};

const DownloadContent = (state = initial_state, action) => {
  switch (action.type) {
    case GET_DOWNLOAD_CONTENT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_DOWNLOAD_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        download: action.payload,
        error: "",
      };
    case GET_DOWNLOAD_CONTENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default DownloadContent;
