import { call, put, takeEvery } from "redux-saga/effects";
import {
  addHomeContentBegin,
  addHomeContentFail,
  addHomeContentSuccess,
  addLanguageBegin,
  addLanguageFail,
  addLanguageSuccess,
  getHomeContentBegin,
  getHomeContentFail,
  getHomeContentSuccess,
  getLanguageBegin,
  getLanguageFail,
  getLanguageSuccess,
} from "./action";
import { del, get, update } from "../../helpers/api_helpers";
import { ADD_HOME_CONTENT, GET_HOME_CONTENT } from "./actionTypes";

function* getHomeContent({ data }) {
  try {
    yield put(getHomeContentBegin());
    const response = yield call(get, `/home_content`, data);
    if (response) {
      yield put(getHomeContentSuccess(response.result));
    }
  } catch (error) {
    yield put(getHomeContentFail(error));
  }
}

function* addHomeContent({ data, callback, id }) {
  try {
    yield put(addHomeContentBegin());

    const response = yield call(update, `/admin/home_content/${id}`, data);

    if (response) {
      yield put(addHomeContentSuccess());
      callback && callback();
    }
  } catch (error) {
    yield put(addHomeContentFail(error));
  }
}

function* HomeContentSaga() {
  yield takeEvery(GET_HOME_CONTENT, getHomeContent);
  yield takeEvery(ADD_HOME_CONTENT, addHomeContent);
}

export default HomeContentSaga;
