import {
  GET_LANGUAGES,
  GET_LANGUAGES_BEGIN,
  GET_LANGUAGES_FAIL,
  GET_LANGUAGES_SUCCESS,
} from "./actionTypes";

const init_state = {
  languages: {},
  loading: false,
  error: "",
};

const Languages = (state = init_state, action) => {
  switch (action.type) {
    case GET_LANGUAGES_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        languages: action.payload,
        error: "",
      };
    case GET_LANGUAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Languages;
