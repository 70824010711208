import {
  GET_HEADER_CONTENT,
  GET_HEADER_CONTENT_BEGIN,
  GET_HEADER_CONTENT_FAIL,
  GET_HEADER_CONTENT_SUCCESS,
} from "./actionTypes";

export const getHeaderContent = ({ data }) => ({
  type: GET_HEADER_CONTENT,
  data: data,
});

export const getHeaderContentBegin = () => ({
  type: GET_HEADER_CONTENT_BEGIN,
});

export const getHeaderContentSuccess = (language) => ({
  type: GET_HEADER_CONTENT_SUCCESS,
  payload: language,
});

export const getHeaderContentFail = (error) => ({
  type: GET_HEADER_CONTENT_FAIL,
  payload: error,
});
