import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import Header from "../../components/header/navbar";
import Search from "../../components/search/search";
import { getNewsList } from "../../store/News/action";
import HighlightNews from "./HighlightNews";
import NewsCategory from "./NewsCategory";
import RecentPosts from "./RecentPosts";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import i18n from "i18next";
import Footer from "../../components/footer/footer";
import axios from "axios";

const NewsRoom = ({ dir, lang, cookie }) => {
  const HeaderContent = useSelector((state) => state.HeaderContent);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const newsDetails = useSelector((state) => state.News);
  const selected = localStorage?.getItem("i18nextLng");
  const basicRequest = {
    page: 1,
    page_count: 6,
    keyword: "",
  };
  const [request, setRequest] = useState({ ...basicRequest });

  useEffect(() => {
    request &&
      dispatch(getNewsList({ request: { ...request, lang: selected } }));
  }, [request, selected]);

  const location = useLocation();

  // useEffect(() => {
  //   i18n.changeLanguage(location.pathname.slice(1, 6));
  // }, [location.pathname]);

  useEffect(() => {
    cookie &&
      axios
        .get("https://backendapi.flashat.com/save_user_data")
        .then()
        .catch((er) => console.log(er));
  }, [cookie]);

  return (
    <>
      {newsDetails?.loading && <div className="animated yt-loader"></div>}

      <Header direction={dir} language={lang} url="news" />
      <div className="me-blog-grid me-padder-top" data-testid="component-news">
        <Container>
          <Row>
            <HighlightNews
              newsDetails={newsDetails}
              request={request}
              setRequest={setRequest}
              title={request?.cat_name}
              direction={dir}
              language={lang}
              t={t}
              selected={localStorage?.getItem("i18nextLng")}
              total={newsDetails?.total}
              HeaderContent={HeaderContent}
            />
            <div className="col-lg-3">
              <div className="me-blog-sidebar ">
                <div className=" mb-4">
                  {!newsDetails?.loading &&
                  newsDetails?.latestNewsList?.length ? (
                    <Search
                      keyword={request.keyword}
                      request={request}
                      setRequest={setRequest}
                      direction={dir}
                      t={t}
                      HeaderContent={HeaderContent}
                    />
                  ) : null}
                </div>

                <div className="me-widget me-widget-category category-responsive">
                  {!newsDetails?.loading &&
                  newsDetails?.categories?.news_category?.length ? (
                    <h4 className="me-widget-title rtl-title">
                      {HeaderContent?.header?.category}
                    </h4>
                  ) : null}

                  <NewsCategory
                    selected={localStorage?.getItem("i18nextLng")}
                    keyword={request.keyword}
                    request={request}
                    setRequest={setRequest}
                    total={newsDetails?.total}
                    t={t}
                    HeaderContent={HeaderContent}
                  />
                </div>

                <div
                  className={`me-widget me-widget-recent-post ${
                    selected === "ar-AE" ? "recent-rtl-post" : ""
                  }`}
                >
                  {!newsDetails?.loading &&
                  newsDetails?.latestNewsList?.length ? (
                    <h4 className="me-widget-title rtl-title">
                      {HeaderContent?.header?.recentPosts}
                    </h4>
                  ) : (
                    <div className="blank"></div>
                  )}
                  <RecentPosts
                    newsRoom={true}
                    selected={localStorage?.getItem("i18nextLng")}
                    direction={dir}
                    language={lang}
                    t={t}
                  />
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      {/* {!newsDetails?.loading && <Footer location={location.pathname} />} */}
    </>
  );
};

export default NewsRoom;
