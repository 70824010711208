import { call, put, takeEvery } from "redux-saga/effects";
import {
  contactUsBegin,
  contactUsCategoryBegin,
  contactUsCategoryFail,
  contactUsCategorySuccess,
  contactUsFail,
  contactUsSuccess,
} from "./actions";
import { CONTACT_US, GET_CONTACT_US_CATEGORY } from "./actionTypes";
import { add, get } from "../../helpers/api_helpers";

function* contactUs({ message, toggle }) {
  try {
    yield put(contactUsBegin());
    const response = yield call(add, "/submit_contact_us", message);
    if (response) {
      yield put(contactUsSuccess(response));
      toggle && toggle();
    }
  } catch (error) {
    yield put(contactUsFail(error));
  }
}

function* contactUsCategory({ id }) {
  try {
    yield put(contactUsCategoryBegin());
    const response = yield call(get, "/get_contact_subject", { lang: id });
    if (response) {
      yield put(contactUsCategorySuccess(response.result));
    }
  } catch (error) {
    yield put(contactUsCategoryFail(error));
  }
}

function* contactUsSaga() {
  yield takeEvery(CONTACT_US, contactUs);
  yield takeEvery(GET_CONTACT_US_CATEGORY, contactUsCategory);
}

export default contactUsSaga;
