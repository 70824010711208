import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/navbar";
import { base_URL } from "../../helpers/api_helpers";
import { getAccountDelete } from "../../store/accountDelete/action";
import { getDownloadContent } from "../../store/download/action";

const AccountDelete = ({ cookie }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [account_delete_heading, setaccount_delete_heading] = useState("");
  const [account_delete_precaution, setaccount_delete_precaution] =
    useState("");
  const location = useLocation();
  const selected = localStorage?.getItem("i18nextLng");

  const accountDeleteData = useSelector((state) => state.AccountDelete);
  console.log("account_delete", accountDeleteData);

  useEffect(() => {
    cookie &&
      axios
        .get(base_URL() + "/save_user_data")
        .then()
        .catch((er) => console.log(er));
  }, [cookie]);

  useEffect(() => {
    dispatch(getAccountDelete({ data: { lang: selected } }));
  }, [selected, location.pathname]);

  return (
    <>
      <Header url="account-delete" />
      <section
        className="why-use-this-app me-blog-grid me-padder-top"
        style={{ paddingBottom: 0 }}
      >
        <div className="container">
          <div className="row row-one">
            <div className="col-lg-6 d-flex align-self-center">
              <div className="left-area content">
                <div className="section-title extra-padding">
                  <h2 className="title">
                    {" "}
                    {accountDeleteData?.account_delete.account_delete_heading}
                  </h2>
                  <ul className="list-group list-group-flush ml-3">
                    {accountDeleteData?.account_delete.steps?.map(
                      (step, index) => {
                        return (
                          <li key={index}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: step.step,
                              }}
                              className="mt-4"
                            >
                              {/* <span style={{ fontWeight: "bold" }}>
                                Step {index + 1} :
                              </span>{" "}
                              <span> {step.step} </span> */}
                            </p>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        accountDeleteData?.account_delete
                          ?.account_delete_precaution,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-first order-lg-last">
              <div className="right-area">
                <div className="img">
                  <img
                    key={accountDeleteData?.account_delete?.image1}
                    alt="account-delete"
                    src={accountDeleteData?.account_delete?.image1}
                    style={{ maxWidth: "500px", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountDelete;
