import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/flashat-logo.svg";
import Header from "../../components/header/navbar";
import { getLegalDocument } from "../../store/Policies/action";
import Loader from "../../components/Loader";
import { useParams } from "react-router";
import Pages404 from "../utility/pages-404";
const PrivacyPolicy = ({ policy_type, title, url }) => {
  console.log();
  const legalDoc = useSelector((state) => state.Policies);
  const params = useParams();
  const selected = localStorage?.getItem("i18nextLng");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getLegalDocument({
        data: { policy_name: params?.id, lang: selected },
      })
    );
  }, [selected]);

  console.log(legalDoc);

  useEffect(() => {
    const scroll = document?.querySelector(".scroll");

    window?.addEventListener("scroll", () => {
      scroll?.classList?.toggle("active", window.scrollY > 200);
    });

    function scrollToTop() {
      window?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    scroll?.addEventListener("click", () => {
      scrollToTop();
    });
  }, []);

  return (
    <>
      {legalDoc?.policyData?.status ? (
        <>
          <Header url={`policy/${params?.id}`} />
          <div
            className="container"
            style={{
              direction: selected == "ar-AE" ? "rtl" : "ltr",
              textAlign: selected == "ar-AE" ? "right" : "left",
            }}
          >
            <div className="scroll">
              <i className="fa fa-chevron-right top"></i>
            </div>
            {!legalDoc?.loading && legalDoc?.policyData ? (
              <div className="policy">
                {/* <img
                  className="img-fluid full-img responsive-policy-image"
                  src={logo}
                  alt=""
                  width="150px"
                /> */}
                <p
                  style={{ fontSize: "2.3rem", textTransform: "uppercase" }}
                  className="responsive-policy-title"
                >
                  {legalDoc?.policyData?.policy_header}
                </p>
                <div
                  className="responsive-policy-content"
                  dangerouslySetInnerHTML={{
                    __html: legalDoc?.policyData?.policy_content,
                  }}
                />
              </div>
            ) : (
              <div className="blank"></div>
            )}
          </div>
          {legalDoc?.loading && <Loader />}
        </>
      ) : !legalDoc?.loading ? (
        <Pages404 />
      ) : (
        <div className="blank"></div>
      )}
    </>
  );
};

export default PrivacyPolicy;
