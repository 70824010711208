import { call, put, takeEvery } from "redux-saga/effects";
import {
  getDownloadContentBegin,
  getDownloadContentFail,
  getDownloadContentSuccess,
} from "./action";
import { get } from "../../helpers/api_helpers";
import { GET_DOWNLOAD_CONTENT } from "./actionTypes";

function* getDownloadContent({ data }) {
  try {
    yield put(getDownloadContentBegin());
    const response = yield call(get, `/download_content`, data);
    if (response) {
      yield put(getDownloadContentSuccess(response.result));
    }
  } catch (error) {
    yield put(getDownloadContentFail(error));
  }
}

function* DownloadContentSaga() {
  yield takeEvery(GET_DOWNLOAD_CONTENT, getDownloadContent);
}

export default DownloadContentSaga;
