export const CONTACT_US = "CONTACT_US";
export const CONTACT_US_BEGIN = "CONTACT_US_BEGIN";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAIL = "CONTACT_US_FAIL";

export const GET_CONTACT_US_CATEGORY = "GET_CONTACT_US_CATEGORY";
export const GET_CONTACT_US_CATEGORY_BEGIN = "GET_CONTACT_US_CATEGORY_BEGIN";
export const GET_CONTACT_US_CATEGORY_SUCCESS =
  "GET_CONTACT_US_CATEGORY_SUCCESS";
export const GET_CONTACT_US_CATEGORY_FAIL = "GET_CONTACT_US_CATEGORY_FAI";
