import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Images
import errorImg from "../../assets/images/404-error.png";
import Header from "../../components/header/navbar";

const Pages404 = () => {
  useEffect(() => {
    document.body.className = "authentication-bg cust-auth-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const selected = localStorage?.getItem("i18nextLng");

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Header languageToggle={true} />

      <div className="my-5 pt-sm-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="text-center">
                <div>
                  <Row className="row justify-content-center">
                    <Col sm={4}>
                      <div className="error-img">
                        <img
                          src={errorImg}
                          alt=""
                          className="img-fluid mx-auto d-block"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <h4 className="text-uppercase mt-4">Sorry, page not found</h4>
                <p className="text-muted">
                  It will be as simple as Accidental in fact, it will be
                  Accidental
                </p>
                <div className="mt-5">
                  <Link className="btn home-btn" to={`/${selected}`}>
                    Back to Home Page
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Pages404;
