import { call, put, takeEvery } from "redux-saga/effects";
import {
  getAccountDeleteBegin,
  getAccountDeleteFail,
  getAccountDeleteSuccess,
} from "./action";
import { get } from "../../helpers/api_helpers";
import { GET_ACCOUNT_DELETE } from "./actionTypes";

function* getAccountDelete({ data }) {
  try {
    yield put(getAccountDeleteBegin());
    const response = yield call(get, `/get_delete_account`, data);
    if (response) {
      yield put(getAccountDeleteSuccess(response.result));
    }
  } catch (error) {
    yield put(getAccountDeleteFail(error));
  }
}

function* AccountDeleteSaga() {
  yield takeEvery(GET_ACCOUNT_DELETE, getAccountDelete);
}

export default AccountDeleteSaga;
