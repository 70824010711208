import { all, fork } from "redux-saga/effects";
import contactUsSaga from "./ContactUs/saga";
import newsSaga from "./News/saga";
import faqSaga from "./FAQ/saga";
import { policySaga } from "./Policies/saga";
import HomePageSaga from "./home/saga";
import LanguagesSaga from "./languages/saga";
import DownloadContentSaga from "./download/saga";
import HeaderContentSaga from "./headerandfooter/saga";
import AccountDeleteSaga from "./accountDelete/saga";

export default function* rootSaga() {
  yield all([
    fork(contactUsSaga),
    fork(newsSaga),
    fork(faqSaga),
    fork(policySaga),
    fork(HomePageSaga),
    fork(LanguagesSaga),
    fork(DownloadContentSaga),
    fork(HeaderContentSaga),
    fork(AccountDeleteSaga),
  ]);
}
