import {
  GETFAQ,
  GETFAQ_BEGIN,
  GETFAQ_SUCCESS,
  GETFAQ_FAIL,
  COUNT_FAQ_HELPFUL_RESPONSE,
  COUNT_FAQ_HELPFUL_RESPONSE_BEGIN,
  COUNT_FAQ_HELPFUL_RESPONSE_SUCCESS,
  COUNT_FAQ_HELPFUL_RESPONSE_FAIL,
  COUNT_FAQ_NOT_HELPFUL_RESPONSE,
  COUNT_FAQ_NOT_HELPFUL_RESPONSE_BEGIN,
  COUNT_FAQ_NOT_HELPFUL_RESPONSE_SUCCESS,
  COUNT_FAQ_NOT_HELPFUL_RESPONSE_FAIL,
} from "./actionType";

export const getFAQ = ({ request }) => ({
  type: GETFAQ,
  request: request,
});

export const getFAQBegin = () => ({
  type: GETFAQ_BEGIN,
});

export const getFAQSuccess = (QandA) => ({
  type: GETFAQ_SUCCESS,
  payload: QandA,
});

export const getFAQFail = (error) => ({
  type: GETFAQ_FAIL,
  payload: error,
});

export const countFaqHelpfulResponse = ({ id, callback }) => ({
  type: COUNT_FAQ_HELPFUL_RESPONSE,
  id: id,
  callback: callback,
});

export const countFaqHelpfulResponseBegin = () => ({
  type: COUNT_FAQ_HELPFUL_RESPONSE_BEGIN,
});

export const countFaqHelpfulResponseSuccess = () => ({
  type: COUNT_FAQ_HELPFUL_RESPONSE_SUCCESS,
});

export const countFaqHelpfulResponseFail = (error) => ({
  type: COUNT_FAQ_HELPFUL_RESPONSE_FAIL,
  payload: error,
});

export const countFaqNotHelpfulResponse = ({ id }) => ({
  type: COUNT_FAQ_NOT_HELPFUL_RESPONSE,
  id: id,
});

export const countFaqNotHelpfulResponseBegin = () => ({
  type: COUNT_FAQ_NOT_HELPFUL_RESPONSE_BEGIN,
});

export const countFaqNotHelpfulResponseSuccess = () => ({
  type: COUNT_FAQ_NOT_HELPFUL_RESPONSE_SUCCESS,
});

export const countFaqNotHelpfulResponseFail = (error) => ({
  type: COUNT_FAQ_NOT_HELPFUL_RESPONSE_FAIL,
  payload: error,
});
