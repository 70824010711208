export const GET_LEGAL_DOCUMENT_TYPE = "GET_LEGAL_DOCUMENT_TYPE";
export const GET_LEGAL_DOCUMENT_TYPE_BEGIN = "GET_LEGAL_DOCUMENT_TYPE_BEGIN";
export const GET_LEGAL_DOCUMENT_TYPE_SUCCESS =
  "GET_LEGAL_DOCUMENT_TYPE_SUCCESS";
export const GET_LEGAL_DOCUMENT_TYPE_FAIL = "GET_LEGAL_DOCUMENT_TYPE_FAIL";

export const GET_LEGAL_DOCUMENT = "GET_LEGAL_DOCUMENT";
export const GET_LEGAL_DOCUMENT_BEGIN = "GET_LEGAL_DOCUMENT_BEGIN";
export const GET_LEGAL_DOCUMENT_SUCCESS = "GET_LEGAL_DOCUMENT_SUCCESS";
export const GET_LEGAL_DOCUMENT_FAIL = "GET_LEGAL_DOCUMENT_FAIL";
