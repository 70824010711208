import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEWS,
  GET_LATEST_NEWS,
  GET_NEWS,
  GET_NEWS_CATEGORY,
  GET_NEWS_LIST,
} from "./actionTypes";
import { add, get } from "../../helpers/api_helpers";
import {
  addNewsBegin,
  addNewsFail,
  addNewsSuccess,
  getNewsListBegin,
  getNewsListSuccess,
  getNewsListFail,
  getNewsBegin,
  getNewsSuccess,
  getNewsFail,
  getLatestNewsListSuccess,
  getLatestNewsListFail,
  getLatestNewsListBegin,
  getNewsCategoryBegin,
  getNewsCategorySuccess,
  getNewsCategoryFail,
} from "./action";

function* getNewsList({ request }) {
  try {
    yield put(getNewsListBegin());
    const response = yield call(get, "/list_news", request);
    if (response) {
      yield put(getNewsListSuccess(response.result));
    }
  } catch (error) {
    yield put(getNewsListFail(error));
  }
}

function* getLatestNews({ lang }) {
  try {
    yield put(getLatestNewsListBegin());

    const response = yield call(get, "/get_latest_news", { lang: lang });
    if (response) {
      yield put(getLatestNewsListSuccess(response.result));
    }
  } catch (error) {
    yield put(getLatestNewsListFail(error));
  }
}

function* getNews({ id, lang }) {
  try {
    yield put(getNewsBegin());

    const response = yield call(get, `/get_news/${id}`, { lang: lang });
    if (response) {
      yield put(getNewsSuccess(response.result.news));
    }
  } catch (error) {
    yield put(getNewsFail(error));
  }
}

function* getNewsCategory({ id }) {
  try {
    yield put(getNewsCategoryBegin());

    const response = yield call(get, `/get_news_category`, { lang: id });
    if (response) {
      yield put(getNewsCategorySuccess(response.result));
    }
  } catch (error) {
    yield put(getNewsCategoryFail(error));
  }
}

function* addNews(news) {
  try {
    yield put(addNewsBegin());
    const response = yield call(add, "/list-news", news.payload);
    if (response) {
      yield put(addNewsSuccess(response));
    }
  } catch (error) {
    yield put(addNewsFail(error));
  }
}

function* newsSaga() {
  yield takeEvery(GET_NEWS_LIST, getNewsList);
  yield takeEvery(GET_LATEST_NEWS, getLatestNews);
  yield takeEvery(GET_NEWS, getNews);
  yield takeEvery(GET_NEWS_CATEGORY, getNewsCategory);
  yield takeEvery(ADD_NEWS, addNews);
}

export default newsSaga;
