import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";

const ConfirmationAlert = ({ content, modal_center, setmodal_center, t }) => {
  const HeaderContent = useSelector((state) => state.HeaderContent);

  return (
    <Modal isOpen={modal_center} centered={true}>
      <div className="modal-header" style={{ direction: "ltr" }}>
        <h5 className="modal-title mt-0">Success</h5>
      </div>
      <div className="modal-body">{content}</div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => setmodal_center(false)}
        >
          {HeaderContent?.header?.ok}
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmationAlert;
