import {
  ADD_HOME_CONTENT_BEGIN,
  ADD_HOME_CONTENT_FAIL,
  ADD_HOME_CONTENT_SUCCESS,
  GET_HOME_CONTENT_BEGIN,
  GET_HOME_CONTENT_FAIL,
  GET_HOME_CONTENT_SUCCESS,
} from "./actionTypes";

export const initial_state = {
  home: {},
  loading: false,
  error: "",
  firstLanguage: "",
};

const HomeContent = (state = initial_state, action) => {
  switch (action.type) {
    case GET_HOME_CONTENT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_HOME_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        home: action.payload,
        error: "",
      };
    case GET_HOME_CONTENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_HOME_CONTENT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ADD_HOME_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case ADD_HOME_CONTENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default HomeContent;
