import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import i18n from "i18next";

import {
  countFaqHelpfulResponse,
  countFaqNotHelpfulResponse,
  getFAQ,
} from "../../store/FAQ/action";
import { Link } from "react-scroll";
import thumbup from "../../assets/icons/thumbup.svg";
import thumbdown from "../../assets/icons/thumbdown.svg";
import thumbup1 from "../../assets/icons/thumbup1.svg";
import thumbdown1 from "../../assets/icons/thumbdown1.svg";
import Header from "../../components/header/navbar";
import { base_URL } from "../../../src/helpers/api_helpers";
import { useTranslation } from "react-i18next";
import axios from "axios";

const FAQ = ({ cookie }) => {
  const [isHoveredup, setIsHoveredUp] = useState(false);
  const [isHoveredDown, setIsHoveredDown] = useState(false);
  const [hoverId, setHoverId] = useState(null);

  const dispatch = useDispatch();

  const faqData = useSelector((state) => state.FAQ);
  const HeaderContent = useSelector((state) => state.HeaderContent);

  const selected = localStorage?.getItem("i18nextLng");

  const basicRequest = {
    page: 1,
    page_count: 6,
    keyword: "",
    lang: selected,
  };
  const [request, setRequest] = useState({ ...basicRequest });
  const [id, setId] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    request && dispatch(getFAQ({ request: { ...request, lang: selected } }));
  }, [request, selected]);

  const handleHelpfulClick = (question, id) => {
    dispatch(
      countFaqHelpfulResponse({
        id: id,
        callback: setId((array) => [...array, question]),
      })
    );
  };

  const handleNotHelpfulClick = (question, id) => {
    dispatch(
      countFaqNotHelpfulResponse({
        id: id,
        callback: setId((array) => [...array, question]),
      })
    );
  };

  useEffect(() => {
    cookie &&
      axios
        .get(base_URL() + "/save_user_data")

        .catch((er) => console.log(er));
  }, []);

  return (
    <>
      {!faqData?.loading && <div className="animated yt-loader"></div>}

      <Header url="help-center" />

      <div className="me-blog-grid me-padder-top faq-container">
        <Container>
          {!faqData?.loading && (
            <div className=" ">
              <h1 className="font-weight-bold mb-5">
                {" "}
                {HeaderContent?.header?.helpCenter?.substr(0, 1) +
                  HeaderContent?.header?.helpCenter?.substr(1).toLowerCase()}
              </h1>
            </div>
          )}
          {!faqData?.loading && faqData?.faq?.length ? (
            <>
              <div>
                <div className=" ">
                  {faqData?.faq?.map((fandq, index) => (
                    <div key={index}>
                      <h5
                        className="mt-5 mb-4 font-weight-bold"
                        style={{ color: "#424040" }}
                      >
                        {fandq.data.length ? fandq.category : null}
                      </h5>
                      {fandq.data.map((faq, index) => (
                        <div key={index}>
                          <li
                            style={{ listStyle: "none" }}
                            className="faq-link-click"
                          >
                            <Link
                              className="faq-link "
                              activeClass="active"
                              to={faq.question}
                              spy={true}
                            >
                              {faq.question}
                            </Link>
                          </li>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : !faqData?.loading ? (
            <div>
              <p className="ml-3 mt-5"> {HeaderContent?.header?.noData}</p>
              <div className="blank"></div>
            </div>
          ) : (
            <div className="blank"></div>
          )}

          <div className="faq-qa">
            {!faqData?.loading && faqData?.faq?.length
              ? faqData?.faq?.map((fandq, index) => (
                  <div key={index}>
                    {fandq.data.length ? (
                      <h4 id={fandq.question} className="mt-5 font-weight-bold">
                        {fandq.category}
                      </h4>
                    ) : null}

                    {fandq.data.map((faq) => (
                      <li
                        style={{ listStyle: "none" }}
                        id={faq.question}
                        key={faq.faq_id}
                      >
                        <h6
                          className="my-4 font-weight-bold"
                          style={{ color: "#424040" }}
                        >
                          {faq.question}
                        </h6>
                        <div>
                          <div
                            dangerouslySetInnerHTML={{ __html: faq.answer }}
                          />
                          {/* <p>{faq.answer}</p> */}
                          {!id.includes(faq.question) ? (
                            <div>
                              <span className="mr-2 faq-bar">..</span>
                              <span className="mr-3">
                                {HeaderContent?.header?.helpful}
                              </span>
                              <span
                                className="mr-3"
                                onClick={() => {
                                  handleHelpfulClick(faq.question, faq.faq_id);
                                  setIsHoveredUp(false);
                                  setIsHoveredDown(false);
                                }}
                                onMouseEnter={() => {
                                  setIsHoveredUp(true);
                                  setHoverId(faq.id);
                                }}
                                onMouseLeave={() => {
                                  setIsHoveredUp(false);
                                  setHoverId(faq.id);
                                }}
                              >
                                <img
                                  src={
                                    isHoveredup && hoverId === faq.id
                                      ? thumbup1
                                      : thumbup
                                  }
                                  className="thumb-icon"
                                  alt="icon"
                                />
                              </span>

                              <span
                                onClick={() => {
                                  setIsHoveredUp(false);
                                  setIsHoveredDown(false);
                                  handleNotHelpfulClick(
                                    faq.question,
                                    faq.faq_id
                                  );
                                }}
                                onMouseEnter={() => {
                                  setIsHoveredDown(true);
                                  setHoverId(faq.id);
                                }}
                                onMouseLeave={() => {
                                  setIsHoveredDown(false);
                                  setHoverId(faq.id);
                                }}
                              >
                                <img
                                  src={
                                    isHoveredDown && hoverId === faq.id
                                      ? thumbdown1
                                      : thumbdown
                                  }
                                  alt="icon"
                                  className={`thumb-icon ${
                                    selected == "ar-AE" ? " mr-3" : ""
                                  }`}
                                />
                              </span>
                            </div>
                          ) : (
                            <p style={{ color: "#29aae1" }}>
                              <span
                                className={`${
                                  selected == "ar-AE" ? "ml-2" : "mr-2"
                                } faq-bar`}
                              >
                                ..
                              </span>
                              {HeaderContent?.header?.feedbackMessage}
                            </p>
                          )}
                        </div>
                      </li>
                    ))}
                  </div>
                ))
              : null}
            <p className="mt-4" style={{ direction: "ltr" }}>
              For More Details Please
              <a href={`/${selected}/contact`} className="faq-link-click ml-1">
                Contact Us
              </a>
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default FAQ;
