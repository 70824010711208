import {
  GET_DOWNLOAD_CONTENT,
  GET_DOWNLOAD_CONTENT_BEGIN,
  GET_DOWNLOAD_CONTENT_FAIL,
  GET_DOWNLOAD_CONTENT_SUCCESS,
} from "./actionTypes";

export const getDownloadContent = ({ data }) => ({
  type: GET_DOWNLOAD_CONTENT,
  data: data,
});

export const getDownloadContentBegin = () => ({
  type: GET_DOWNLOAD_CONTENT_BEGIN,
});

export const getDownloadContentSuccess = (language) => ({
  type: GET_DOWNLOAD_CONTENT_SUCCESS,
  payload: language,
});

export const getDownloadContentFail = (error) => ({
  type: GET_DOWNLOAD_CONTENT_FAIL,
  payload: error,
});
